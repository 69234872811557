import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import NavbarMex from '../components/navbarMex';
import { utils, writeFile } from 'xlsx';
import BotonWhatsapp from '../botonWhatsapp/botonWhatsapp';
import './TableComponent.css';

const TableComponent = () => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [filterEstado, setFilterEstado] = useState('');
  const [dateRange, setDateRange] = useState({
    startDate: '',
    endDate: ''
  });
  const usuario_id = localStorage.getItem('usuario_id');
  const navigate = useNavigate();

  useEffect(() => {
    axios.get(`https://api.99envios.app/api/online/envios_completos/${usuario_id}`)
      .then(response => {
        const transformedData = response.data.map(item => ({
          ...item,
          codigo: item.codigo_sucursal,
          fecha_envio: formatDateForDisplay(item.fecha_envio),
          numero_guia: item.numero_de_guia,
          producto: item.Producto,
          destinatario: item.nombre_destinatario,
          direccion: item.direccion_destinatario,
          telefono: item.telefono_destinatario,
          ciudad: item.ciudad_destino,
          valor: item.valor_comercial,
          valor_servicio: item.valor_servicio,
          aplica_contrapago: item.aplica_contrapago,
          estado: item.estado_del_envio,
          aplica_contrapago: item.aplica_contrapago ,
          fecha_actualizacion: formatDateForDisplay(item.fecha_actualizacion),
          pdf: item.pdf
        }));
        setData(transformedData);
      })
      .catch(error => console.error('Error fetching data: ', error));
  }, [usuario_id]);

  // Helper function to format dates consistently
  const formatDateForDisplay = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return dateString; // Return original if invalid
    return date.toISOString().split('T')[0];
  };

  // Helper function to compare dates safely
  const compareDates = (date1, date2) => {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    
    // Check if either date is invalid
    if (isNaN(d1.getTime()) || isNaN(d2.getTime())) {
      return false;
    }
    
    // Reset time portion for date-only comparison
    d1.setHours(0, 0, 0, 0);
    d2.setHours(0, 0, 0, 0);
    
    return d1.getTime() === d2.getTime();
  };

  // Decodificación y visualización del PDF en base64
  const handlePdfView = (base64Pdf) => {
    if (base64Pdf) {
      const pdfWindow = window.open();  // Abrir una nueva ventana
      const pdfUrl = `data:application/pdf;base64,${base64Pdf}`;
      pdfWindow.document.write(`<iframe src="${pdfUrl}" width="100%" height="100%" />`);
    } else {
      alert('PDF no disponible para este envío');
    }
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
    setCurrentPage(1);
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const handleDateChange = (type, value) => {
    setDateRange(prev => ({ ...prev, [type]: value }));
    setCurrentPage(1); // Reset to first page when changing filters
  };

  const sortedData = React.useMemo(() => {
    let sortableData = [...data];
    if (sortConfig.key !== '') {
      sortableData.sort((a, b) => {
        if (sortConfig.key === 'fecha_envio' || sortConfig.key === 'fecha_actualizacion') {
          const dateA = new Date(a[sortConfig.key]);
          const dateB = new Date(b[sortConfig.key]);
          return sortConfig.direction === 'ascending' 
            ? dateA - dateB
            : dateB - dateA;
        }
        
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [data, sortConfig]);

  const filteredData = sortedData.filter(item => {
    // Search filter
    const matchesSearch = Object.values(item).some(val => 
      val && val.toString().toLowerCase().includes(search.toLowerCase())
    );
    
    // Estado filter
    const matchesEstado = filterEstado === '' || 
      (filterEstado === 'Entregado' && (item.estado === 'Entregada' || item.estado === 'Entregado')) ||
      item.estado === filterEstado;
    
    // Date filter
    let matchesDate = true;
    if (dateRange.startDate || dateRange.endDate) {
      const itemDate = new Date(item.fecha_envio);
      itemDate.setHours(0, 0, 0, 0);

      if (dateRange.startDate) {
        const startDate = new Date(dateRange.startDate);
        startDate.setHours(0, 0, 0, 0);
        if (itemDate < startDate) matchesDate = false;
      }

      if (dateRange.endDate && matchesDate) {
        const endDate = new Date(dateRange.endDate);
        endDate.setHours(0, 0, 0, 0);
        if (itemDate > endDate) matchesDate = false;
      }
    }

    return matchesSearch && matchesEstado && matchesDate;
  });

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = filteredData.slice(indexOfFirstEntry, indexOfLastEntry);
  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const exportToExcel = () => {
    // Crear una copia de los datos filtrados excluyendo la columna pdf
    const exportData = filteredData.map(item => {
      const { pdf, ...rest } = item;
      return rest;
    });

    const worksheet = utils.json_to_sheet(exportData);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Envíos Completos");

    // Ajustar el ancho de las columnas
    const wscols = [
      {wch: 15}, // codigo
      {wch: 12}, // fecha_envio
      {wch: 15}, // numero_guia
      {wch: 20}, // producto
      {wch: 25}, // destinatario
      {wch: 30}, // direccion
      {wch: 15}, // telefono
      {wch: 15}, // ciudad
      {wch: 12}, // valor
      {wch: 12}, // valor servicio
      {wch: 12}, // aplica contrapago
      {wch: 15}, // estado
      {wch: 12}  // fecha_actualizacion
    ];

    worksheet['!cols'] = wscols;

    writeFile(workbook, `Envios_Completos_${formatDateForDisplay(new Date())}.xlsx`);
  };

  const columns = useMemo(
    () => [
      // ... otras columnas existentes ...
      {
        Header: "Valor Servicio",
        accessor: "valor_servicio",
        Cell: ({ value }) => (
          <span>
            ${new Intl.NumberFormat('es-CO').format(value)}
          </span>
        )
      },
      {
        Header: "Aplica Contrapago",
        accessor: "aplica_contrapago", 
        Cell: ({ value }) => {
          return (
            <span>
              {parseInt(value) === 1 ? "No" : "Si"}
            </span>
          );
        }
      },
      // ... otras columnas existentes ...
    ],
    []
  );

  return (
    <>
      <NavbarMex title="Envios Completos" />
      <div className="table-containerEC">
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
          <button onClick={() => navigate('/historico-envios')} className="historicos-button">
            Históricos Envios
          </button>
        </div>

        <div className="table-controls-fecha">
          <div style={{marginRight: "10px"}}>
            <label>Fecha inicial</label>
            <input
              type="date"
              value={dateRange.startDate}
              onChange={(e) => handleDateChange('startDate', e.target.value)}
              max={dateRange.endDate || undefined}
            />
          </div>
          <div>
            <label>Fecha final</label>
            <input
              type="date"
              value={dateRange.endDate}
              onChange={(e) => handleDateChange('endDate', e.target.value)}
              min={dateRange.startDate || undefined}
            />
          </div>
        </div>

        <div className="table-controls">
          
          <div className="controls-group">
            <label htmlFor="entries">Mostrar</label>
            <select
              id="entries"
              name="entries"
              value={entriesPerPage}
              onChange={(e) => setEntriesPerPage(parseInt(e.target.value))}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>

            <input
              type="text"
              placeholder="Buscar"
              value={search}
              onChange={handleSearch}
              className="search-input"
            />
          </div>

          <div className="filters-group">
            <select
              value={filterEstado}
              onChange={(e) => setFilterEstado(e.target.value)}
              className="filter-select"
            >
              <option value="">Todos los estados</option>
              <option value="Entregado">Entregado</option>
              <option value="Creado">Creado</option>
              <option value="Envío programado">Envío programado</option>
            </select>
          </div>

          <div className="export-group">
            <button onClick={exportToExcel} className="export-button">Exportar a Excel</button>
          </div>
        </div>

        <div className="table-wrapper">
          <table className="custom-table">
            <thead>
              <tr>
                <th onClick={() => handleSort('codigo')}>Código</th>
                <th onClick={() => handleSort('fecha_envio')}>Fecha envío</th>
                <th onClick={() => handleSort('numero_guia')}>Número de guía</th>
                <th onClick={() => handleSort('producto')}>Producto</th>
                <th onClick={() => handleSort('destinatario')}>Destinatario</th>
                <th onClick={() => handleSort('direccion')}>Dirección</th>
                <th onClick={() => handleSort('telefono')}>Teléfono</th>
                <th onClick={() => handleSort('ciudad')}>Ciudad</th>
                <th onClick={() => handleSort('valor')}>Valor</th>
                <th onClick={() => handleSort('valor_servicio')}>Flete</th>
                <th onClick={() => handleSort('aplica_contrapago')}>Credito</th>
                <th onClick={() => handleSort('estado')}>Estado</th>
                <th onClick={() => handleSort('fecha_actualizacion')}>Fecha Actualización</th>
                <th>PDF</th>
              </tr>
            </thead>
            <tbody>
              {currentEntries.map((item, index) => (
                <tr key={index}>
                  <td>{item.codigo}</td>
                  <td>{item.fecha_envio}</td>
                  <td>{item.numero_guia}</td>
                  <td>{item.producto}</td>
                  <td>{item.destinatario}</td>
                  <td>{item.direccion}</td>
                  <td>{item.telefono}</td>
                  <td>{item.ciudad}</td>
                  <td>{item.valor}</td>
                  <td>{item.valor_servicio}</td>
                  <td>{parseInt(item.aplica_contrapago) === 1 ? "No" : "Si"}</td>
                  <td>{item.estado}</td>
                  <td>{item.fecha_actualizacion}</td>
                  <td>
                    {item.pdf ? (
                      <button
                        onClick={() => handlePdfView(item.pdf)}
                        className="pdf-button"
                      >
                        Ver PDF
                      </button>
                    ) : (
                      <span className="no-pdf">No disponible</span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="pagination">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              className={`page-button ${currentPage === index + 1 ? 'active' : ''}`}
              onClick={() => setCurrentPage(index + 1)}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>
      <BotonWhatsapp />
    </>
  );
};

export default TableComponent;