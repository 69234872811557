import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Card } from 'antd';
import './SuscriptorForm.css';
import NavbarPicking from '../../components/navbarPicking/navbarPicking';

const SubscriberForm = () => {
  const [form] = Form.useForm();  // Crear referencia al formulario para poder manipularlo
  const [loading, setLoading] = useState(false); // Estado para manejar el loading mientras se carga la API

  useEffect(() => {
    // Función para hacer la llamada a la API y obtener los datos
    const fetchSucursalData = async () => {
      setLoading(true);
      try {
        const id = localStorage.getItem('usuario_id'); // Obtener el ID de la sucursal del localStorage
        const response = await fetch(`https://99envios.app/api/online/sucursal/${id}`);
        const data = await response.json();

        // Prellenar el formulario con los datos de la API
        form.setFieldsValue({
          idSucursal: data.codigo_sucursal,
          nombre: data.nombre,
          email: data.nombre_sucursal, // Ajustar según la API si es correcto
          telefono: data.telefono,
          direccion: data.direccion,
          ciudad: data.id_ciudad,
        });
      } catch (error) {
        console.error('Error al obtener los datos de la sucursal:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSucursalData();
  }, [form]);

  const onFinish = (values) => {
    console.log('Valores del formulario:', values);
  };

  return (
    <>
      <NavbarPicking title="Registro de Suscriptor" />
      <div className="subscriber-form-container">
        <Card title="FORMULARIO DE REGISTRO DE SUSCRIPTOR" className="subscriber-form-card">
          <Form
            form={form}
            name="subscriber"
            layout="vertical"
            onFinish={onFinish}
            autoComplete="off"
          >
            <div className="subscriber-form-grid">
              <Form.Item
                label="ID Sucursal"
                name="idSucursal"
                rules={[{ required: true, message: 'Por favor ingrese el ID de sucursal' }]}
              >
                <Input placeholder="2119" disabled={loading} />
              </Form.Item>

              <Form.Item
                label="Nombre del Suscriptor"
                name="nombre"
                rules={[{ required: true, message: 'Por favor ingrese el nombre' }]}
              >
                <Input placeholder="Ingrese nombre completo" disabled={loading} />
              </Form.Item>

              <Form.Item
                label="Correo Electrónico"
                name="email"
                rules={[
                  { required: true, message: 'Por favor ingrese el correo' },
                  { type: 'email', message: 'Ingrese un correo válido' }
                ]}
              >
                <Input placeholder="ejemplo@gmail.com" disabled={loading} />
              </Form.Item>

              <Form.Item
                label="Teléfono"
                name="telefono"
                rules={[{ required: true, message: 'Por favor ingrese el teléfono' }]}
              >
                <Input placeholder="317749971" disabled={loading} />
              </Form.Item>

              <Form.Item
                label="Dirección"
                name="direccion"
                rules={[{ required: true, message: 'Por favor ingrese la dirección' }]}
              >
                <Input placeholder="carrera 105a #70a 09" disabled={loading} />
              </Form.Item>

              <Form.Item
                label="Ciudad"
                name="ciudad"
                rules={[{ required: true, message: 'Por favor ingrese la ciudad' }]}
              >
                <Input placeholder="11001000" disabled={loading} />
              </Form.Item>
            </div>

            <Form.Item>
              <Button type="primary" htmlType="submit" className="subscriber-submit-button" loading={loading}>
                Registrar
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </>
  );
};

export default SubscriberForm;
