import React, { useState, useEffect } from 'react';
import { Button, message, Spin, Table, Modal, Radio, Select } from 'antd';
import NavbarMex from '../components/navbarMex';
import { useParams } from 'react-router-dom';
import sampleFile from '../Masivo/envio_masivo.xlsx';
import BotonWhatsapp from '../botonWhatsapp/botonWhatsapp';
import * as XLSX from 'xlsx';
import useFileUpload from './excel';
import './EnvioMasivo.css';

const { Option } = Select;

const EnvioMasivo = () => {
  const { id } = useParams(); 
  const [archivo, setArchivo] = useState(null);
  const [cotizacion, setCotizacion] = useState(null);
  const [worksheet, setWorksheet] = useState(null);
  const [selectedTransportadoras, setSelectedTransportadoras] = useState({});
  const [sucursalId, setSucursalId] = useState(null);
  const { uploadFile, loading, error, success } = useFileUpload();
  const [submitting, setSubmitting] = useState(false);
  const [archivoNombre, setArchivoNombre] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [cotizacionModalVisible, setCotizacionModalVisible] = useState(false);
  const [globalTransportadora, setGlobalTransportadora] = useState(null);
  const [useGlobalTransportadora, setUseGlobalTransportadora] = useState(false);

  useEffect(() => {
    if (id) {
      localStorage.setItem('id', id);
    }
  }, [id]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const allowedTypes = [
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ];

    if (file && allowedTypes.includes(file.type)) {
      setArchivo(file);
      setArchivoNombre(file.name);
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        setWorksheet(workbook.Sheets[sheetName]);
      };
      reader.readAsArrayBuffer(file);
    } else {
      message.error('Por favor seleccione un archivo Excel válido.');
    }
  };

  const handleSubmit = async () => {
    if (!archivo) {
      message.error('Por favor seleccione un archivo Excel.');
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append('archivo', archivo);

    try {
      setSubmitting(true);
      const response = await uploadFile(formDataToSend);
      setCotizacion(response);
      setModalVisible(false);
      setCotizacionModalVisible(true);
      message.success('Archivo cargado con éxito');
    } catch (err) {
      message.error(`Error: ${err.message}`);
    } finally {
      setSubmitting(false);
    }
  };

  const handleTransportadoraSelect = (cotizacionKey, transportadoraKey) => {
    setSelectedTransportadoras((prevSelected) => ({
      ...prevSelected,
      [cotizacionKey]: transportadoraKey,
    }));
  };

  const handleConfirmSelection = async () => {
    const newWorkbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(newWorkbook, worksheet, 'Sheet1');
    const newFile = XLSX.write(newWorkbook, { bookType: 'xlsx', type: 'array' });

    const blob = new Blob([newFile], { type: 'application/octet-stream' });

    const formDataToSend = new FormData();
    formDataToSend.append('archivo', blob, 'archivo_modificado.xlsx');

    try {
      setSubmitting(true);
      const response = await fetch(`https://integration.99envios.app/api/sucursal/importar_preenvio/${sucursalId}`, {
        method: 'POST',
        body: formDataToSend,
      });
      const data = await response.json();
      message.success('Archivo enviado con éxito');
    } catch (error) {
      message.error('Error al enviar el archivo');
    } finally {
      setSubmitting(false);
    }

    setCotizacionModalVisible(false);
  };

  return (
    <>
      <NavbarMex title="Envío Masivo" />
      <Spin spinning={loading}>
        <div className="envio-masivo">
          <h2>Envío Masivo</h2>
          <input type="file" accept=".xlsx, .xls" onChange={handleFileChange} />

          <Button
            type="primary"
            href={sampleFile}
            download
            style={{ backgroundColor: 'green', borderColor: 'green', marginTop: '10px' }}
          >
            Descargar plantilla
          </Button>

          <div className="button-container">
            <Button type="primary" onClick={() => setModalVisible(true)} disabled={loading || !archivo}>
              Cargar y Editar
            </Button>
          </div>

          <Radio.Group
            value={useGlobalTransportadora}
            onChange={() => setUseGlobalTransportadora(!useGlobalTransportadora)}
            style={{ marginTop: '20px' }}
          >
            <Radio value={true}>Usar una misma transportadora para todas las órdenes</Radio>
            <Radio value={false}>Seleccionar una transportadora por cada orden</Radio>
          </Radio.Group>

          {useGlobalTransportadora && (
            <Select
              style={{ width: 450, marginTop: '10px' }}
              placeholder="Seleccionar transportadora para todas las cotizaciones"
              onChange={(value) => setGlobalTransportadora(value)}
              value={globalTransportadora}
            >
              <Option value="interrapidisimo">Interrapidisimo</Option>
              <Option value="tcc">TCC</Option>
              <Option value="servientrega">Servientrega</Option>
              <Option value="coordinadora">Coordinadora</Option>
            </Select>
          )}

          <Modal
            title="Confirmar Envío"
            visible={modalVisible}
            onOk={handleSubmit}
            onCancel={() => setModalVisible(false)}
            centered
          >
            <p>¿Está seguro que desea enviar el archivo <strong>{archivoNombre}</strong>?</p>
          </Modal>

          <Modal
            title="Seleccionar Transportadora"
            visible={cotizacionModalVisible}
            onOk={handleConfirmSelection}
            onCancel={() => setCotizacionModalVisible(false)}
            centered
          >
            <Table
              dataSource={cotizacion ? Object.entries(cotizacion).map(([key, value]) => ({ key, ...value })) : []}
              columns={[
                { title: 'Envío', dataIndex: 'key', key: 'key' },
                {
                  title: 'Transportadora',
                  key: 'transportadora',
                  render: (_, record) => (
                    Object.keys(record).map((transportadoraKey) => (
                      <Radio
                        checked={selectedTransportadoras[record.key] === transportadoraKey}
                        onChange={() => handleTransportadoraSelect(record.key, transportadoraKey)}
                        key={transportadoraKey}
                      >
                        {transportadoraKey}
                      </Radio>
                    ))
                  ),
                },
              ]}
              pagination={false}
            />
          </Modal>
        </div>
      </Spin>
      <BotonWhatsapp />
    </>
  );
};

export default EnvioMasivo;
