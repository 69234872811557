import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import NavbarMex from '../components/navbarMex';
import BotonWhatsapp from '../botonWhatsapp/botonWhatsapp';
import { utils, writeFile } from 'xlsx';
import './cartera.css';

const TableComponentC = () => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [filterEntregado, setFilterEntregado] = useState('');
  const [filterPagado, setFilterPagado] = useState('');
  const [estadoEnvioOptions, setEstadoEnvioOptions] = useState([]);
  const [estadoPagoOptions, setEstadoPagoOptions] = useState([]);

  const usuario_id = localStorage.getItem('usuario_id');
  const navigate = useNavigate();

  useEffect(() => {
    axios.get(`https://api.99envios.app/api/online/cartera_completa/${usuario_id}`)
      .then(response => {
        setData(response.data);

        // Extraer valores únicos de 'estado_del_envio'
        const uniqueEstadoEnvio = [...new Set(response.data.map(item => item.estado_del_envio).filter(Boolean))];
        setEstadoEnvioOptions(uniqueEstadoEnvio);

        // Extraer valores únicos de 'estado_del_pago'
        const uniqueEstadoPago = [...new Set(response.data.map(item => item.estado_pago).filter(Boolean))];
        setEstadoPagoOptions(uniqueEstadoPago);
      })
      .catch(error => console.error('Error fetching data: ', error));
}, [usuario_id]);


  const handleSearch = (event) => {
    setSearch(event.target.value);
    setCurrentPage(1);
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedData = React.useMemo(() => {
    let sortableData = [...data];
    if (sortConfig.key !== '') {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [data, sortConfig]);

  const filteredData = sortedData.filter(item => {
    const searchMatch = (
      (item.nombre_destinatario?.toLowerCase() || '').includes(search.toLowerCase()) ||
      (item.direccion_destinatario?.toLowerCase() || '').includes(search.toLowerCase()) ||
      (item.telefono_destinatario?.toLowerCase() || '').includes(search.toLowerCase()) ||
      (item.ciudad_destino?.toLowerCase() || '').includes(search.toLowerCase()) ||
      (item.estado_del_envio?.toLowerCase() || '').includes(search.toLowerCase()) ||
      (item.nombre_sucursal?.toLowerCase() || '').includes(search.toLowerCase()) ||
      item.valor_comercial?.toString().toLowerCase().includes(search.toLowerCase()) ||
      item.valor_flete?.toString().toLowerCase().includes(search.toLowerCase()) ||
      item.valor_consignado?.toString().toLowerCase().includes(search.toLowerCase()) ||
      (item.fecha_consignacion?.toLowerCase() || '').includes(search.toLowerCase())
    );

    const entregadoMatch = filterEntregado === '' || 
      item.estado_del_envio?.toLowerCase() === filterEntregado.toLowerCase();

    const pagadoMatch = filterPagado === '' || 
      item.estado_pago?.toLowerCase() === filterPagado.toLowerCase();

    return searchMatch && entregadoMatch && pagadoMatch;
});

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = filteredData.slice(indexOfFirstEntry, indexOfLastEntry);
  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const formatDateForDisplay = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return dateString; // Return original if invalid
    return date.toISOString().split('T')[0];
  };

  const exportToExcel = () => {
    const worksheet = utils.json_to_sheet(data);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Data");
    writeFile(workbook, `Cartera_Completa_${formatDateForDisplay(new Date())}.xlsx`);
  };

  return (
    <>
      <NavbarMex title="Cartera Completa" />
      <div className="table-containerCA">
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
          <button onClick={() => navigate('/historico-cartera')} className="historicos-button">
            Históricos Cartera
          </button>
        </div>

        <div className="table-controls">
          <div className="controls-group">
            <label htmlFor="entries">Mostrar</label>
            <select
              id="entries"
              name="entries"
              value={entriesPerPage}
              onChange={(e) => {
                setEntriesPerPage(parseInt(e.target.value));
                setCurrentPage(1);
              }}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <input
              type="text"
              placeholder="Buscar"
              value={search}
              onChange={handleSearch}
              className="search-input"
            />
          </div>
          <div className="filters-group">
            <select
              value={filterEntregado}
              onChange={(e) => {
                setFilterEntregado(e.target.value);
                setCurrentPage(1);
              }}
              className="filter-select"
            >
              <option value="">Estado Envío</option>
              {estadoEnvioOptions.map((estado, index) => (
                <option key={index} value={estado.toLowerCase()}>
                  {estado}
                </option>
              ))}
            </select>
            <select
              value={filterPagado}
              onChange={(e) => {
                setFilterPagado(e.target.value);
                setCurrentPage(1);
              }}
              className="filter-select"
            >
              <option value="">Estado Pago</option>
              {estadoPagoOptions.map((estado, index) => (
                <option key={index} value={estado.toLowerCase()}>
                  {estado}
                </option>
              ))}
            </select>
          </div>
          <div className="export-group">
            <button onClick={exportToExcel} className="export-button">Exportar a Excel</button>
          </div>
        </div>
        <div className="table-wrapper">
          <table className="custom-table">
            <thead>
              <tr>
                <th onClick={() => handleSort('codigo_sucursal')}>Código Sucursal</th>
                <th onClick={() => handleSort('numero_de_guia')}>Número de Guía</th>
                <th onClick={() => handleSort('valor_comercial')}>Valor Comercial</th>
                <th onClick={() => handleSort('valor_flete')}>Valor Flete</th>
                <th onClick={() => handleSort('valor_consignado')}>Valor Consignado</th>
                <th onClick={() => handleSort('fecha_consignacion')}>Fecha de Consignación</th>
                <th onClick={() => handleSort('estado_del_envio')}>Estado de Envío</th>
                <th onClick={() => handleSort('estado_del_pago')}>Estado de Pago</th>
                <th onClick={() => handleSort('nombre_destinatario')}>Destinatario</th>
                <th onClick={() => handleSort('direccion_destinatario')}>Dirección</th>
                <th onClick={() => handleSort('valor_servicio')}>Valor Servicio</th>
                <th onClick={() => handleSort('telefono_destinatario')}>Teléfono</th>
                <th onClick={() => handleSort('ciudad_destino')}>Ciudad</th>
              </tr>
            </thead>
            <tbody>
              {currentEntries.map((item, index) => (
                <tr key={index}>
                  <td>{item.codigo_sucursal}</td>
                  <td>{item.numero_de_guia}</td>
                  <td>{item.valor_comercial}</td>
                  <td>{item.valor_flete}</td>
                  <td>{item.valor_consignado}</td>
                  <td>{item.fecha_consignacion}</td>
                  <td>{item.estado_del_envio}</td>
                  <td>{item.estado_pago}</td>
                  <td>{item.nombre_destinatario}</td>
                  <td>{item.direccion_destinatario}</td>
                  <td>{item.valor_servicio}</td>
                  <td>{item.telefono_destinatario}</td>
                  <td>{item.ciudad_destino}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="pagination">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              className={`page-button ${currentPage === index + 1 ? 'active' : ''}`}
              onClick={() => setCurrentPage(index + 1)}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>
      <BotonWhatsapp />
    </>
  );
};

export default TableComponentC;
