import React, { useState } from 'react';
import { Table, Input, Button } from 'antd';
import NavbarSales from '../../components/navbar/navbarSales';
import 'antd/dist/antd.css';
import './PagoVendedores.css';

const PagoVendedores = () => {
  const [amount, setAmount] = useState(0);

  const handleInputChange = (e) => {
    setAmount(e.target.value);
  };

  const handleSubmit = () => {
    alert(`Retiraste: ${amount}`);
  };

  const columns = [
    {
      title: 'Sucursal',
      dataIndex: 'sucursal',
      key: 'sucursal',
    },
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
    },
    {
      title: 'Envios a la fecha',
      dataIndex: 'envios',
      key: 'envios',
    },
  ];

  const data = [
    {
      key: '1',
      sucursal: '116395',
      nombre: 'ESTATUSTIENDAONLINE',
      envios: 27,
    },
    {
      key: '2',
      sucursal: '103185',
      nombre: 'FREDY RAMIREZ',
      envios: 26,
    },
    {
      key: '3',
      sucursal: '134684',
      nombre: 'IMPORTADORA FOKUS SAS',
      envios: 26,
    },
    {
      key: '4',
      sucursal: '126403',
      nombre: 'NOVASTYLE HD SAS',
      envios: 24,
    },
    // Añade más datos según sea necesario
  ];

  return (
    <>
        <NavbarSales title="Pago a Vendedores" />
    <div className="pago-vendedores-container">
      <div className="pago-vendedores-header">
        ¡Qué emocionante ver tus avances! Solo un esfuerzo más y estarás celebrando el éxito.
      </div>

      <div className="pago-vendedores-module">
        <div className="pago-vendedores-amount">
          <span className="material-icons-sharp pago-vendedores-bank-icon">account_balance</span>
          <h2>Total Disponible</h2>
          <p>$ 50000</p>
        </div>

        <div className="pago-vendedores-info">
          <span className="material-icons-sharp pago-vendedores-info-icon">info</span>
          <h2>¡Atención, equipo ventas!</h2>
          <p>
            ¡La cuenta regresiva para el retiro mensual ha comenzado! Solo tienen una
            oportunidad al mes para hacerlo, así que ¡hagámoslo con sabiduría y estilo!
          </p>
        </div>
      </div>

      <div className="pago-vendedores-withdraw">
        <label htmlFor="withdrawAmount">Cantidad a Retirar</label>
        <Input
          id="withdrawAmount"
          type="number"
          value={amount}
          onChange={handleInputChange}
        />
        <Button type="primary" onClick={handleSubmit}>Enviar</Button>
      </div>

      <h3>Sucursales próximas a comisionar</h3>
      <Table columns={columns} dataSource={data} pagination={false} />
    </div>
    </>
  );
};

export default PagoVendedores;
