import React, { useState, useEffect, useRef } from 'react';
import { Table, Input, Dropdown, Menu, Button, Select, Modal } from 'antd';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import NavbarDropshipping from '../navbarDropshipping/navbarDropshipping';
import './misPedidos.css';

const { Option } = Select;

const ProductTablePedidos = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [filters, setFilters] = useState({
    orderStatus: '',
    carrier: '',
    warehouse: '',
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  
  const tableContainerRef = useRef(null);
  const topScrollRef = useRef(null);

  // Mapeo de los estados de la orden
  const orderStatusMap = {
    1: 'Creado',
    2: 'Distribucion',
    3: 'Entregado',
    4: 'Rechazado',
    5: 'Devolucion',
    6: 'Anulado',
    7: 'Otro',
    8: 'Error'
  };

  // Función para consumir la API
  const user_id = localStorage.getItem('usuario_id');
  const fetchOrders = async () => {
    try {
      const response = await fetch(`https://api.99envios.app/api/listar_ordenes/${user_id}`);
      const result = await response.json();
      const formattedData = result.map((item, index) => ({
        key: index.toString(),
        id: index + 1,
        productName: item.nombre_producto,
        orderDate: item.fecha_estado,
        client: `${item.destinatario.nombre} ${item.destinatario.primer_apellido} ${item.destinatario.segundo_apellido || ''}`.trim(),
        phone: item.destinatario.telefono,
        orderStatus: item.preeenvio.estado?.nombre || 'Desconocido', 
        carrier: item.transportadora,
        shippingType: item.estado_envio,
        rawData: item, // Guardamos los datos completos para usarlos en el modal
      }));
      setData(formattedData);
      setFilteredData(formattedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Carga los datos al montar el componente
  useEffect(() => {
    fetchOrders();
  }, []);

  // Sincronizar el desplazamiento de las barras
  const syncScroll = (sourceRef, targetRef) => {
    if (sourceRef.current && targetRef.current) {
      targetRef.current.scrollLeft = sourceRef.current.scrollLeft;
    }
  };

  const handleSearch = (value) => {
    setSearchText(value);
    const filtered = data.filter(
      (item) =>
        item.productName.toLowerCase().includes(value.toLowerCase()) ||
        item.client.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filtered);
  };

  const handleFilterChange = (filterName, value) => {
    setFilters({ ...filters, [filterName]: value });
  };

  useEffect(() => {
    let result = data;
    if (searchText) {
      result = result.filter(
        (item) =>
          item.productName.toLowerCase().includes(searchText.toLowerCase()) ||
          item.client.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    if (filters.orderStatus) {
      result = result.filter((item) => item.orderStatus === filters.orderStatus);
    }
    if (filters.carrier) {
      result = result.filter((item) => item.carrier === filters.carrier);
    }
    if (filters.warehouse) {
      result = result.filter((item) => item.warehouse === filters.warehouse);
    }
    setFilteredData(result);
  }, [searchText, filters, data]);

  const menu = (
    <Menu>
      <Menu.Item key="1">Acción 1</Menu.Item>
      <Menu.Item key="2">Acción 2</Menu.Item>
      <Menu.Item key="3">Acción 3</Menu.Item>
    </Menu>
  );

  const showModal = (record) => {
    setSelectedOrder(record.rawData);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: '',
      dataIndex: 'checkbox',
      key: 'checkbox',
      render: () => <input type="checkbox" />,
    },
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Nombre del producto',
      dataIndex: 'productName',
      key: 'productName',
    },
    {
      title: 'Fecha de la orden',
      dataIndex: 'orderDate',
      key: 'orderDate',
    },
    {
      title: 'Cliente',
      dataIndex: 'client',
      key: 'client',
    },
    {
      title: 'Teléfono',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Estatus de la Orden',
      dataIndex: 'orderStatus',
      key: 'orderStatus',
    },
    {
      title: 'Transportadora',
      dataIndex: 'carrier',
      key: 'carrier',
    },
    {
      title: 'Tipo de Envío',
      dataIndex: 'shippingType',
      key: 'shippingType',
    },
    {
      title: 'Detalles',
      key: 'details',
      render: (_, record) => <a onClick={() => showModal(record)}>Ver detalles</a>,
    },
  ];

  return (
    <>
      <NavbarDropshipping title="Envíos Dropshipping"/>
      <div className='misPedidos'>
        <div className="filters">
          <Dropdown overlay={menu}>
            <Button>
              Acciones <DownOutlined />
            </Button>
          </Dropdown>
          <Input
            placeholder="Buscar"
            prefix={<SearchOutlined />}
            style={{ width: '200px', marginLeft: '10px' }}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <Select
            style={{ width: '200px', marginRight: '10px' }}
            placeholder="Filtrar por Estatus"
            onChange={(value) => handleFilterChange('orderStatus', value)}
          >
            <Option value="">Todos</Option>
            <Option value="Creado">Creado</Option>
            <Option value="Distribucion">Distribucion</Option>
            <Option value="Entregado">Entregado</Option>
            <Option value="Rechazado">Rechazado</Option>
            <Option value="Devolucion">Devolucion</Option>
            <Option value="Anulado">Anulado</Option>
            <Option value="Otro">Otro</Option>
            <Option value="Error">Error</Option>
          </Select>
          <Select
            style={{ width: '200px', marginRight: '10px' }}
            placeholder="Filtrar por Transportadora"
            onChange={(value) => handleFilterChange('carrier', value)}
          >
            <Option value="">Todas</Option>
            <Option value="interrapidisimo">Interrapidisimo</Option>
            <Option value="coordinadora">Coordinadora</Option>
            <Option value="servientrega">Servientrega</Option>
            <Option value="envia">Envia</Option>
          </Select>
        </div>

        <div className="scroll-container" ref={topScrollRef} onScroll={() => syncScroll(topScrollRef, tableContainerRef)}>
          <div className="scroll-bar"></div>
        </div>

        <div className="misPedidos-tabla" ref={tableContainerRef} onScroll={() => syncScroll(tableContainerRef, topScrollRef)}>
          <Table
            columns={columns}
            dataSource={filteredData}
            pagination={{
              pageSize: 10,
              showSizeChanger: false,
              showQuickJumper: false,
            }}
            scroll={{ x: 'max-content' }}  // Habilitar desplazamiento horizontal
            locale={{ emptyText: 'No hay datos disponibles' }}
          />
        </div>
      </div>
      <Modal
        title="Detalles del Envío"
        visible={isModalVisible}
        onOk={handleOk}
        footer={[
          <Button key="submit" type="primary" onClick={handleOk}>
            OK
          </Button>
        ]}
        width={500}
      >
        {selectedOrder && (
          <div>
            <p><strong>Nombre del producto:</strong> {selectedOrder.nombre_producto}</p>
            <p><strong>Valor de venta:</strong> ${selectedOrder.valor_venta}</p>
            <p><strong>Valor envío:</strong> ${selectedOrder.valor_envio}</p>
            <p><strong>Transportadora:</strong> {selectedOrder.transportadora}</p>
            <p><strong>Estado envío:</strong> {selectedOrder.estado_envio}</p>
            <p><strong>Nombre destinatario:</strong> {selectedOrder.destinatario.nombre} {selectedOrder.destinatario.primer_apellido} {selectedOrder.destinatario.segundo_apellido || ''}</p>
            <p><strong>Teléfono destinatario:</strong> {selectedOrder.destinatario.telefono}</p>
            <p><strong>Dirección destinatario:</strong> {selectedOrder.destinatario.direccion}</p>
            <p><strong>Correo destinatario:</strong> {selectedOrder.destinatario.correo}</p>
            <p><strong>Ciudad destinatario:</strong> {selectedOrder.destinatario.ciudad}</p>
          </div>
        )}
      </Modal>
    </>
  );
};

export default ProductTablePedidos;
