import React, { useState } from 'react';
import { Form, Input, Button, Row, Select, message } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import NavbarAdminMex from '../components/navbar';

const { Option } = Select;

const UserFormColaborador = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const response = await fetch('https://api.99envios.app/api/online/rol', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log('API response:', data);
      message.success('Colaborador creado exitosamente');
      form.resetFields();
    } catch (error) {
      console.error('Error:', error);
      message.error('Hubo un error al crear el colaborador');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <NavbarAdminMex title={"Nuevo Colaborador"}/>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        style={{ maxWidth: '500px', margin: '0 auto', padding: '20px', background: '#fff', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', marginTop: '2%' }}
      >
        <Row gutter={16}>
          <Form.Item
            label="Rol Colaborador"
            name="rol"
            rules={[{ required: true, message: 'Por favor, selecciona el rol' }]}
          >
            <Select placeholder="Selecciona el rol">
              <Option value="5">Agente de Ingresos</Option>
              <Option value="6">Agente de Llamadas</Option>
              <Option value="7">Gestor de Cartera</Option>
              <Option value="8">Vendedor</Option>
            </Select>
          </Form.Item>
        </Row>
        <Row gutter={16}>
          <Form.Item
            label="Correo Colaborador"
            name="correo"
            rules={[{ required: true, message: 'Por favor, ingresa el correo electrónico', type: 'email' }]}
          >
            <Input placeholder="Ingresa el correo electrónico" />
          </Form.Item>
        </Row>
        <Row gutter={16}>
          <Form.Item
            label="Contraseña Colaborador"
            name="contrasena"
            rules={[{ required: true, message: 'Por favor, ingresa la contraseña' }]}
          >
            <Input.Password
              placeholder="Ingresa la contraseña"
              iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            />
          </Form.Item>
        </Row>
        <Form.Item>
          <Button 
            type="primary" 
            htmlType="submit" 
            block 
            style={{ backgroundColor: '#8c8cff', width: '100px', height: '44px', marginTop:' 2%'}}
            loading={loading}
          >
            Enviar
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default UserFormColaborador;