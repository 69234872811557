import React, { useState, useEffect, useMemo, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import BotonWhatsapp from '../../botonWhatsapp/botonWhatsapp';
import NavbarMex from '../../components/navbarMex';
import { utils, writeFile } from 'xlsx';
import './recoleccionTabla.css';
import RecoleccionModal from '../recoleccionModal/recoleccionModal';
import { Modal, Switch, Alert, Spin, Button } from 'antd';

const RecoleccionTabla = () => {
  const [data, setData] = useState([]);
  const [selectedGuias, setSelectedGuias] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [guiaToDelete, setGuiaToDelete] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isProgramadasLoading, setIsProgramadasLoading] = useState(true);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [isActive, setIsActive] = useState(() => {
    return localStorage.getItem('recoleccionActive') === 'true';
  });

  // Regular table state
  const [search, setSearch] = useState('');
  const [selectedCarrier, setSelectedCarrier] = useState('');
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  // Programadas table state
  const [searchProgramadas, setSearchProgramadas] = useState('');
  const [selectedCarrierProgramadas, setSelectedCarrierProgramadas] = useState('');
  const [entriesPerPageProgramadas, setEntriesPerPageProgramadas] = useState(10);
  const [currentPageProgramadas, setCurrentPageProgramadas] = useState(1);

  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const [programadasData, setProgramadasData] = useState([]);

  const navigate = useNavigate();
  const carriers = ['servientrega', 'interrapidisimo'];

  useEffect(() => {
    const fetchRecoleccionStatus = async () => {
      const id_user = localStorage.getItem('usuario_id');
      if (!id_user) return;

      try {
        const response = await axios.get(`https://99envios.app/api/online/estado-recogida/${id_user}`);
        const newStatus = response.data.recoleccion_activa;
        setIsActive(newStatus);
        localStorage.setItem('recoleccionActive', newStatus.toString());
      } catch (error) {
        console.error('Error al obtener estado de recolección', error);
      }
    };

    fetchRecoleccionStatus();
  }, []);

  const handleSwitchChange = async (checked) => {
    const id_user = localStorage.getItem('usuario_id');
    if (!id_user) {
      console.error('id_user no encontrado en localStorage');
      return;
    }

    try {
      const response = await axios.post(`https://99envios.app/api/online/activar-recogida/${id_user}`, {
        recoleccion_activa: checked
      });
      
      if (response.status === 200) {
        // Update both state and localStorage
        setIsActive(checked);
        localStorage.setItem('recoleccionActive', checked.toString());
      }
    } catch (error) {
      console.error('Error al activar/desactivar la recolección', error);
      // Revert the state and localStorage if there's an error
      setIsActive(!checked);
      localStorage.setItem('recoleccionActive', (!checked).toString());
    }
  };

  const handleSelectAll = () => {
    setIsSelectAll(!isSelectAll);
    if (!isSelectAll) {
      setSelectedGuias(filteredData.map(item => item.numero_de_guia));
    } else {
      setSelectedGuias([]);
    }
  };

  // Función para obtener los datos desde la API
  const fetchData = async () => {
    setIsLoading(true);
    const id_user = localStorage.getItem('usuario_id');
    if (!id_user) {
      console.error('id_user no encontrado en localStorage');
      setIsLoading(false);
      return;
    }

    try {
      const response = await axios.get(`https://api.99envios.app/api/online/envios_creados/${id_user}`);
      setData(response.data);
    } catch (error) {
      console.error('Error al obtener los datos de la API', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchProgramadasData = async () => {
    setIsProgramadasLoading(true);
    try {
      const response = await axios.get('https://api.99envios.app/api/online/listar-recogida/1');
      setProgramadasData(response.data);
    } catch (error) {
      console.error('Error al obtener los datos de recolecciones programadas', error);
    } finally {
      setIsProgramadasLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    fetchProgramadasData();
  }, []);

  const handleSearch = (event) => {
    setSearch(event.target.value);
    setCurrentPage(1);
  };

  const handleChange = async (checked) => {
    const id_user = localStorage.getItem('usuario_id');
    if (!id_user) {
      console.error('id_user no encontrado en localStorage');
      return;
    }

    try {
      const response = await axios.post(`https://99envios.app/api/online/activar-recogida/${id_user}`, {
        recoleccion_activa: checked
      });
      setIsActive(checked);  // Actualizar el estado si la petición fue exitosa
    } catch (error) {
      console.error('Error al activar/desactivar la recolección', error);
    }
  };


  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const handleCheckboxChange = (guia) => {
    setSelectedGuias(prev => {
      if (prev.includes(guia)) {
        return prev.filter(g => g !== guia);
      } else {
        return [...prev, guia];
      }
    });
  };

  const handleDeleteGuia = async (guia) => {
    setGuiaToDelete(guia);
    setIsDeleteDialogOpen(true);
  };

  const confirmDeleteGuia = async () => {
    const id_user = localStorage.getItem('usuario_id');
    const guiaItem = data.find(item => item.numero_de_guia === guiaToDelete);

    if (!guiaItem) {
      console.error('Guía no encontrada');
      return;
    }

    const payload = {
      guia: guiaItem.numero_de_guia,
      transportadora: {
        pais: 'colombia',
        nombre: guiaItem.transportadora.toLowerCase()
      }
    };

    try {
      await axios.post(`https://99envios.app/api/online/desactivar_preenvio/${id_user}`, payload);
      // Después de eliminar, actualizamos los datos filtrando la guía eliminada
      setData(prev => prev.filter(item => item.numero_de_guia !== guiaToDelete));
      setSelectedGuias(prev => prev.filter(g => g !== guiaToDelete));
    } catch (error) {
      console.error('Error al eliminar la guía', error);
    }

    setIsDeleteDialogOpen(false);
    setGuiaToDelete(null);
  };

  const cancelDeleteGuia = () => {
    setIsDeleteDialogOpen(false);
    setGuiaToDelete(null);
  };

  const handleCarrierChange = (event) => {
    setSelectedCarrier(event.target.value);
    setCurrentPage(1);
  };

  const handlePdfView = (base64Pdf) => {
    if (base64Pdf) {
      const pdfWindow = window.open();  // Abrir una nueva ventana
      const pdfUrl = `data:application/pdf;base64,${base64Pdf}`;
      pdfWindow.document.write(`<iframe src="${pdfUrl}" width="100%" height="100%" />`);
    } else {
      alert('PDF no disponible para este envío');
    }
  };

  const handleRealizarRecoleccion = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const sortedData = useMemo(() => {
    let sortableData = [...data];
    if (sortConfig.key !== '') {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [data, sortConfig]);

  const sortedDataProgramadas = useMemo(() => {
    let sortableData = [...data]; // or replace `data` with the actual array containing "programadas"
    if (sortConfig.key !== '') {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [data, sortConfig]);

  const filteredData = useMemo(() => {
    return sortedData.filter(item =>
    (Object.values(item).some(val =>
      val && val.toString().toLowerCase().includes(search.toLowerCase())
    ) &&
      (selectedCarrier === '' || item.transportadora.toLowerCase() === selectedCarrier.toLowerCase()))
    );
  }, [sortedData, search, selectedCarrier]);

  const filteredDataProgramadas = useMemo(() => {
    return programadasData.filter(item =>
      (Object.values(item).some(val =>
        val && val.toString().toLowerCase().includes(searchProgramadas.toLowerCase())
      ) &&
        (selectedCarrierProgramadas === '' || item.transportadora.toLowerCase() === selectedCarrierProgramadas.toLowerCase()))
    );
  }, [programadasData, searchProgramadas, selectedCarrierProgramadas]);

  const formatCityDisplay = (cityData) => {
    const [city, region] = cityData.split('/');
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span>{city}</span>
        <span>{region}</span>
      </div>
    );
  };

  // Pagination calculations
  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = filteredData.slice(indexOfFirstEntry, indexOfLastEntry);

  const indexOfLastEntryProgramadas = currentPageProgramadas * entriesPerPageProgramadas;
  const indexOfFirstEntryProgramadas = indexOfLastEntryProgramadas - entriesPerPageProgramadas;
  const currentEntriesProgramadas = filteredDataProgramadas.slice(indexOfFirstEntryProgramadas, indexOfLastEntryProgramadas);

  const totalPages = Math.ceil(filteredData.length / entriesPerPage);
  const totalPagesProgramadas = Math.ceil(filteredDataProgramadas.length / entriesPerPageProgramadas);

  const programadasSectionRef = useRef(null);

  const formatDateForDisplay = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return dateString; // Return original if invalid
    return date.toISOString().split('T')[0];
  };

  const truncateData = data.map(row => {
    const truncatedRow = {};
    for (const key in row) {
      truncatedRow[key] = row[key]?.toString().slice(0, 32767);
    }
    return truncatedRow;
  });
  
  const exportToExcel = () => {
    const worksheet = utils.json_to_sheet(truncateData); // Usa los datos truncados
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Data");
    writeFile(workbook, `Recolección_${formatDateForDisplay(new Date())}.xlsx`);
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <NavbarMex title="Recolección" />
      <div className="table-container-recoleccion">
        <div style={{
          display: 'flex',
          alignItems: 'center',
          gap: '16px',
          padding: '16px',
          borderRadius: '8px',
          marginBottom: '20px'
        }}>
          <span style={{ fontSize: '16px', fontWeight: 500 }}>Solicitud de recolección</span>
          <Switch
            checked={isActive}
            onChange={handleSwitchChange}
            checkedChildren="Encendido"
            unCheckedChildren="Apagado"
            style={{
              backgroundColor: isActive ? '#52c41a' : '#f5222d',
            }}
          />
        </div>
        <Alert
          message={
            <>
              Este botón permite encender o apagar la solicitud de recolección de guías para TCC y Coordinadora. Ten en cuenta que, si activas la recolección antes de las 11:30 a.m., esta será procesada el mismo día. Para gestionar la recolección con Interrapidísimo y Servientrega, consulta el siguiente video instructivo:{" "}
              <Button type="link" onClick={showModal} style={{ padding: 0 }}>
                Ver video aquí.
              </Button>
            </>
          }
          type="info"
          showIcon
          style={{
            marginBottom: "30px",
            marginTop: "-10px",
            marginLeft: "10px",
            marginRight: "10px",
          }}
        />

        <Modal
          title="Instructivo de recolección"
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
          destroyOnClose={true}
          width="100vh"
          style={{ top: 20 }}
          bodyStyle={{ padding: 0 }}
        >
          <div style={{ position: 'relative', paddingBottom: '65%', height: 0, overflow: 'hidden' }}>
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/_Z8ipnfD-_I"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
              }}
            ></iframe>
          </div>
        </Modal>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px', marginLeft: '0' }}>
          <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start' }}>
            <button onClick={() => programadasSectionRef.current.scrollIntoView({ behavior: 'smooth' })} className="recoleccion-button">
              Mis Recolecciones
            </button>
          </div>
          <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
            <button
              onClick={handleRealizarRecoleccion}
              className={`recoleccion-button ${!selectedCarrier ? 'disabled' : ''}`}
              disabled={!selectedCarrier}
              style={{
                opacity: selectedCarrier ? 1 : 0.6,

              }}
            >
              Realizar Recolección ({selectedGuias.length})
            </button>
          </div>
          <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
            <button onClick={exportToExcel} className="export-button">
              Exportar a Excel
            </button>
          </div>
        </div>
        <div className="table-wrapper" style={{ marginTop: '20px' }}>
          <h2>Programar Recolecciones</h2>
          <p>Seleccione una transportadora, marque las guías que desea recolectar y finalmente presione el botón "Realizar recolección".</p>
        </div>
        <div className="table-controls" style={{
              maxWidth: '740px'
            }}>
          <label htmlFor="entries">Mostrar</label>
          <select
            id="entries"
            value={entriesPerPage}
            onChange={(e) => setEntriesPerPage(parseInt(e.target.value))}
            style={{ width: '70px' }}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
          <input
            type="text"
            placeholder="Buscar"
            value={search}
            onChange={handleSearch}
            className="search-input"
            style={{ width: '350px' }}
          />
          <select
            value={selectedCarrier}
            onChange={(e) => setSelectedCarrier(e.target.value)}
            className="carrier-select"
            style={{ minWidth: '230px' }}
          >
            <option value="">Todas las transportadoras</option>
            {carriers.map((carrier, index) => (
              <option key={index} value={carrier}>{carrier}</option>
            ))}
          </select>
        </div>
        <button 
          onClick={handleSelectAll} 
          className='recoleccion-button' 
          style={{marginBottom: '10px'}}
        >
          {isSelectAll ? 'Deseleccionar todo' : 'Seleccionar todo'}
        </button>
        <div className="table-wrapper">
          {isLoading ? (
            <div style={{ 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center', 
              padding: '40px',
              flexDirection: 'column',
              gap: '16px'
            }}>
              <Spin size="large" />
              <span>Cargando datos de recolección...</span>
            </div>
          ) : (
            <table className="custom-table">
            <thead>
              <tr>
                <th></th>
                <th onClick={() => handleSort('numero_de_guia')}>Número de Guía</th>
                <th onClick={() => handleSort('codigo_sucursal')}>Código Sucursal</th>
                <th onClick={() => handleSort('Producto')}>Producto</th>
                <th onClick={() => handleSort('valor_comercial')}>Valor Comercial</th>
                <th onClick={() => handleSort('valor_flete')}>Valor Flete</th>
                <th onClick={() => handleSort('estado_del_envio')}>Estado de Envío</th>
                <th onClick={() => handleSort('nombre_destinatario')}>Destinatario</th>
                <th onClick={() => handleSort('direccion_destinatario')}>Dirección</th>
                <th onClick={() => handleSort('telefono_destinatario')}>Teléfono</th>
                <th onClick={() => handleSort('ciudad_destino')}>Ciudad</th>
                <th onClick={() => handleSort('transportadora')}>Transportadora</th>
                <th>PDF</th>
                <th>Acción</th>
              </tr>
            </thead>
            <tbody>
              {currentEntries.map((item, index) => (
                <tr key={index}>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedGuias.includes(item.numero_de_guia)}
                      onChange={() => handleCheckboxChange(item.numero_de_guia)}
                    />
                  </td>
                  <td>{item.numero_de_guia}</td>
                  <td>{item.codigo_sucursal}</td>
                  <td>{item.Producto}</td>
                  <td>{item.valor_comercial}</td>
                  <td>{item.valor_flete}</td>
                  <td>{item.estado_del_envio || "No creado"}</td>
                  <td>{item.nombre_destinatario}</td>
                  <td>{item.direccion_destinatario}</td>
                  <td>{item.telefono_destinatario}</td>
                  <td>{formatCityDisplay(item.ciudad_destino)}</td>
                  <td>{item.transportadora}</td>
                  <td>
                    {item.pdf ? (
                      <button
                        onClick={() => handlePdfView(item.pdf)}
                        className="pdf-button"
                      >
                        Ver PDF
                      </button>
                    ) : (
                      <span className="no-pdf">No disponible</span>
                    )}
                  </td>
                  <td>
                    <button onClick={() => handleDeleteGuia(item.numero_de_guia)} className="delete-button">
                      Eliminar
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          )}
        </div>
        <div className="pagination">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              className={`page-button ${currentPage === index + 1 ? 'active' : ''}`}
              onClick={() => setCurrentPage(index + 1)}
            >
              {index + 1}
            </button>
          ))}
        </div>
        <div ref={programadasSectionRef} className="table-wrapper" style={{ marginTop: '20px' }}>
          <h2 style={{ marginTop: '20px' }}>Recolecciones Programadas</h2>
        </div>
        <div className="table-controls" style={{ maxWidth: '740px' }}>
          <label htmlFor="entriesProgramadas">Mostrar</label>
          <select
            id="entriesProgramadas"
            value={entriesPerPageProgramadas}
            onChange={(e) => setEntriesPerPageProgramadas(parseInt(e.target.value))}
            style={{ width: '70px' }}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
          <input
            type="text"
            placeholder="Buscar"
            value={searchProgramadas}
            onChange={(e) => {
              setSearchProgramadas(e.target.value);
              setCurrentPageProgramadas(1);
            }}
            className="search-input"
            style={{ width: '350px' }}
          />
          <select
            value={selectedCarrierProgramadas}
            onChange={(e) => setSelectedCarrierProgramadas(e.target.value)}
            className="carrier-select"
            style={{ minWidth: '230px' }}
          >
            <option value="">Todas las transportadoras</option>
            {carriers.map((carrier, index) => (
              <option key={index} value={carrier}>{carrier}</option>
            ))}
          </select>
        </div>
        <div className="table-wrapper">
          {isProgramadasLoading ? (
            <div style={{ 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center', 
              padding: '40px',
              flexDirection: 'column',
              gap: '16px'
            }}>
              <Spin size="large" />
              <span>Cargando recolecciones programadas...</span>
            </div>
          ) : (
            <table className="custom-table">
            <thead>
              <tr>
                <th onClick={() => handleSort('numero_recogida')}>Número de Recogida</th>
                <th onClick={() => handleSort('transportadora')}>Transportadora</th>
                <th onClick={() => handleSort('fecha_recogida')}>Fecha de Recogida</th>
                <th>Números de Preenvíos</th>
              </tr>
            </thead>
            <tbody>
              {currentEntriesProgramadas.map((item, index) => (
                <tr key={index}>
                  <td>{item.numero_recogida}</td>
                  <td>{item.transportadora}</td>
                  <td>{item.fecha_recogida}</td>
                  <td>{item.numeros_preenvios.join(', ')}</td>
                </tr>
              ))}
            </tbody>
          </table>
          )}
        </div>
        <div className="pagination">
          {Array.from({ length: totalPagesProgramadas }, (_, index) => (
            <button
              key={index}
              className={`page-button ${currentPageProgramadas === index + 1 ? 'active' : ''}`}
              onClick={() => setCurrentPageProgramadas(index + 1)}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>
      {isModalOpen && (
        <RecoleccionModal
          initialNumerosPreenvios={selectedGuias}
          selectedCarrier={selectedCarrier}
          onClose={closeModal}
        />
      )}
      <Modal
        visible={isDeleteDialogOpen}
        onOk={confirmDeleteGuia}
        onCancel={cancelDeleteGuia}
        title="¿Está seguro de eliminar la guía?"
        okText="Eliminar"
        cancelText="Cancelar"
      >
        <p>La guía número {guiaToDelete} será eliminada.</p>
      </Modal>
      <BotonWhatsapp />
    </>
  );
};

export default RecoleccionTabla;