
import React from 'react';
import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRef } from 'react';
import Navbar from '../../components/navbarOut';
import Footer from '../../components/footer';
import { Form, Input, Button, message, Card } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

import 'antd/dist/antd.css';
import './iniciarSesion.css';


const IniciarSesion = () => {
  const eyeRef = useRef(null);
  const [isHovering, setIsHovering] = useState(false);


  const handleMouseMove = (event) => {
    if (eyeRef.current) {
      const { left, top, width, height } = eyeRef.current.getBoundingClientRect();
      const eyeCenterX = left + width / 2;
      const eyeCenterY = top + height / 2;
      const angleDeg = angle(eyeCenterX, eyeCenterY, event.clientX, event.clientY);
      eyeRef.current.style.transform = `rotate(${angleDeg}deg)`;
    }
  };

  const angle = (cx, cy, ex, ey) => {
    const dy = ey - cy;
    const dx = ex - cx;
    const rad = Math.atan2(dy, dx); // range (-PI, PI]
    const deg = rad * (180 / Math.PI); // rads to degs, range (-180, 180]
    return deg;
  };

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => {
    // Esta función cambia la visibilidad de la contraseña y actualiza la clase para la animación.
    setIsPasswordVisible(prevState => !prevState);
  };


  const cardStyle = {
    width: '350px',
    borderRadius: '20px',
    overflow: 'hidden',
    boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
    backgroundColor: '#333333',
    color: '#95a8cf',
    padding: '20px',
    position: 'relative', // Añadir posición relativa al contenedor del formulario
  };

  const buttonStyle = {
    width: '100%',
    backgroundColor: '#6C63FF',
    borderColor: '#6C63FF',
    color: 'white',
    height: '40px',
    fontSize: '16px',
  };

  const inputStyle = {
    marginBottom: '20px',
    color: 'blue',
  };

  const handleSubmit = async (values) => {
    try {
      const usuarioData = {
        email: values.email,
        password: values.password,
      };

      const response = await axios.post('https://api.99envios.app/api/auth/login', usuarioData);
      const now = new Date();
      const expirationTime = new Date(now.getTime() + 6 * 3600 * 1000);




      localStorage.setItem('token', response.data.token);  // Almacenar el token en el almacenamiento local}
      localStorage.setItem('nombre', response.data.sucursales[0].nombre);
      localStorage.setItem('usuario_id', response.data.user.id);  // Almacenar el ID del usuario en el almacenamiento local
      localStorage.setItem('rol_id', response.data.user.id_rol);  // Almacenar el ID del rol en el almacenamiento local
      localStorage.setItem('email', response.data.user.email);  // Almacenar el correo electrónico en el almacenamiento local
      console.log(response.data.sucursales[0].nombre); // Esto debería imprimir "usuario de prueba"

      localStorage.setItem('nombre', response.data.sucursales[0].nombre);
      console.log(localStorage.getItem('nombre')); // Esto debería imprimir "usuario de prueba"



      localStorage.setItem('expiration', expirationTime.getTime().toString());
      message.success(`Inicio de sesión exitoso para ${values.email}!`);

      console.log('Inicio de sesión exitoso:', response);
      console.log('Datos de usuario:', response.data.user.id_rol);
      if (response.data.user.id_rol === 1) {
        navigate('/admin');
      } else if (response.data.user.id_rol === 2) {
        navigate('/conductor');
      } else if (response.data.user.id_rol === 3) {
        navigate('/tablero ');
      } else if (response.data.user.id_rol === 8) {
        navigate('/conductor');
      }
    } catch (error) {
      message.error('Error al iniciar sesión. Por favor, verifica tus credenciales.');
      console.error('Error al iniciar sesión:', error);
    }
  };






  return (
    <div>
      <Navbar />
      <div className="content-wrapper">
        <div className="tab-izquierda">
          <a href="/crear/repartidor" className="btn-registro">Regístrate como Repartidor</a>
          <img src={require("../../components/img/repartidor.png")} alt="Imagen" className="imagen-superpuestaRepartidor" />
        </div>
        <div class="formularioL">
          <Card style={cardStyle}>
            <Form
              form={form}
              name="registro"
              onFinish={handleSubmit}
              layout="vertical"
            >
              <h2 style={{ color: 'white', textAlign: 'center', marginBottom: '24px' }}>Iniciar sesión</h2>
              <Form.Item
                label={<span style={{ color: 'white', fontWeight: 'bold' }}>Correo</span>}
                name="email"
                rules={[{ required: true, message: 'Por favor ingresa tu correo!' }]}
              >
                <Input placeholder="Ingresa tu correo" className="dark-placeholder" style={inputStyle} />
              </Form.Item>
              <Form.Item
                label={<span style={{ color: 'white', fontWeight: 'bold' }}>Contraseña</span>}
                name="password"
                rules={[{ required: true, message: 'Por favor ingresa tu contraseña!' }]}
              >
                <div className={`demo_text ${isPasswordVisible ? 'demo_text-processing' : ''}`}>
                  <Input.Password
                    placeholder="Ingresa tu contraseña"
                    className="dark-placeholder"
                    style={inputStyle}
                    iconRender={(visible) => (
                      <div
                        ref={eyeRef}
                        onMouseEnter={() => setIsHovering(true)}
                        onMouseMove={handleMouseMove}
                        onMouseLeave={() => {
                          setIsHovering(false);
                          if (eyeRef.current) {
                            eyeRef.current.style.transform = 'rotate(0deg)'; // Reset rotation when not hovering
                          }
                        }}
                      >
                        {visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />}
                      </div>
                    )}
                  />
                </div>
              </Form.Item>
              <Form.Item>
                <Button class="btnInicioSesion" type="primary" htmlType="submit" className="dark-placeholder" style={buttonStyle}>
                  Iniciar Sesión
                </Button>
              </Form.Item>
              {/* <Form.Item>
                <Button type="default" htmlType="button" style={{ ...buttonStyle, backgroundColor: 'transparent', color: '#687BD4', borderColor: '#6C63FF' }}>
                  <a href="/crear/usuario">Registrarse</a>
                </Button>
              </Form.Item> */}
              <div style={{ textAlign: 'center', color: 'white' }}>
                <a href="https://api.99envios.app/formulario-recuperar-contrasenia" style={{ color: '#6C63FF' }}>Olvidaste tu Contraseña click aquí</a>
              </div>
            </Form>
          </Card>
        </div>
        <div className="tab-derecha">
          <a href="/crear/sucursal" className="btn-registro">Regístrate como Sucursal</a>
          <img src={require("../../components/img/sucursal.png")} alt="Imagen" className="imagen-superpuestaSucursal" />
        </div>

      </div>
      <Footer />
    </div>
  );
};

export default IniciarSesion;
