import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import NavbarDropshipping from '../navbarDropshipping/navbarDropshipping';
import axios from 'axios';
import './ProductCard.css';
import SendClientPage from "../SendClientPage/SendClientPage";
import CloseIcon from '@mui/icons-material/Close';

const ProductCard = () => {
  const [mostrarEnvios, setMostrarEnvios] = useState(false);
  const [suggestedPrice, setSuggestedPrice] = useState(null);
  const [name, setName] = useState(null);
  const [stock, setStock] = useState(null);
  const [prodID, setProdID] = useState(null);
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [sucursal, setSucursal] = useState(null);
  const [selectedImage, setSelectedImage] = useState("");

  const handleButtonClick = (suggestedPrice, stock, name, prodID) => {
    setMostrarEnvios(true);
    setSuggestedPrice(suggestedPrice);
    setStock(stock);
    setName(name);
    setProdID(prodID);
  };

  const handleCloseModal = () => {
    setMostrarEnvios(false);
  };

  const getCategoryName = (categoryId) => {
    const categoryMap = {
        1: "Bisuteria",
        2: "Ropa Deportiva",
        3: "Vaporizadores",
        4: "Mascotas",
        5: "Moda",
        6: "Tecnologia",
        7: "Cocina",
        8: "Belleza",
        9: "Salud",
        10: "Hogar",
        11: "Natural Home",
        12: "Deportes",
        13: "Sex Shop",
        14: "Bebé",
        15: "Aseo",
        16: "Bienestar",
        17: "Camping",
        18: "Pesca",
        19: "Defensa Personal",
        20: "Vehiculos",
        21: "Jugueteria",
        22: "Otros"
    };
    return categoryMap[categoryId] || 'Sin categoría';
  };

  useEffect(() => {
    const fetchProduct = async () => {
      const url = `https://api.99envios.app/api/inventarios_producto/${id}`;
      try {
        const { data } = await axios.get(url);
        const foundProduct = data.inventario;
        if (foundProduct) {
          setProduct(foundProduct);
          setSucursal(data.sucursal);
          setSelectedImage(foundProduct.imagenes[0]?.url_imagen || '/logo.png');
        }
      } catch (error) {
        console.error('Error al cargar el producto:', error);
      }
    };

    fetchProduct();
  }, [id]);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  if (!product) {
    return <p>Cargando...</p>;
  }

  const images = product.imagenes.map(img => img.url_imagen);

  return (
    <>
      <NavbarDropshipping title={product.nombre_producto}/>
      <div className="product-layout">
        <div className="product-card">
          <div className="product-card-header">
            <div className="product-image-section">
              <img
                src={selectedImage}
                alt={product.nombre_producto}
                className="product-image"
              />
              <div className="product-thumbnails">
                {images.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`Thumbnail ${index + 1}`}
                    className="thumbnail-image"
                    onClick={() => handleImageClick(image)}
                  />
                ))}
              </div>
            </div>
            <div className="product-info">
              <h2 className="product-title">{product.nombre_producto}</h2>
              <div className="product-sku-id">
                <p className="product-id">Id producto: {product.id_producto}</p>
                <p className="product-sku">SKU: {product.sku}</p>
              </div>
              <div className="product-tags">
                <span className="tag">{getCategoryName(product.id_categoria)}</span>
              </div>
              <p className="product-type">Tipo de producto: <strong>{product.estado_variacion ? 'Variable' : 'Simple'}</strong></p>
              <div className="product-pricing">
                <p className="product-price-provider">Precio del proveedor: <strong>${parseFloat(product.precio_proveedor).toLocaleString()}</strong></p>
                <p className="product-price-suggested">Precio sugerido: <strong>${parseFloat(product.precio_sugerido).toLocaleString()}</strong></p>
              </div>
              <p className="product-stock">Stock: <strong>{product.cantidad_disponible}</strong></p>
              <div className="product-actions">
                <button className="product-button primary" onClick={() => handleButtonClick(product.precio_sugerido, product.cantidad_disponible, product.nombre_producto, product.id_producto)}>
                  Enviar al cliente
                </button>
              </div>
            </div>
          </div>
          <div className="product-card-details">
            <h3>Detalles</h3>
            <ul>
              <li>Descripción: {product.descripcion_producto}</li>
              <li>Peso: {product.peso_producto} {product.peso_producto === 1 ? 'kilogramo' : 'kilogramos'}</li>
              {product.informacion_adicional && (<li>Información adicional: {product.informacion_adicional}</li>)}
            </ul>
          </div>
          {product.estado_variacion === 1 && product.variaciones && product.variaciones.length > 0 && (
            <div className="product-card-details">
              <h3>Variaciones</h3>
              <ul>
                {product.variaciones.map((variacion, index) => (
                  <li key={index}>
                    Talla: {variacion.talla}, Color: {variacion.color}, Stock: {variacion.cantidad_disponible}, SKU: {variacion.sku}
                  </li>
                ))}
              </ul>
            </div>
          )}
          {sucursal && (
            <div className="product-card-details">
              <h3>Sucursal</h3>
              <ul>
                <li>Nombre de la sucursal: {sucursal.nombre_sucursal}</li>
                <li>Dirección: {sucursal.direccion}</li>
                <li>Teléfono: {sucursal.telefono}</li>
                <li>Contacto: {sucursal.nombre}</li>
              </ul>
            </div>
          )}
          {mostrarEnvios && (
            <div className="modal-overlay" onClick={handleCloseModal}>
              <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <SendClientPage suggestedPrice={suggestedPrice} stock={stock} name={name} prodID={prodID} />
                <button className="close-modal-btn" onClick={handleCloseModal}>
                  <CloseIcon />
                </button>
              </div>
            </div>
          )}
        </div>
        {/*<div className="product-card-adicional-group">
          <div className="product-card-adicional">
            <div className="product-card-header">
              <div className="product-image-section">
                <img
                  src={selectedImage}
                  alt={product.nombre_producto}
                  className="product-image"
                />
                <div className="product-thumbnails">
                  {images.map((image, index) => (
                    <img
                      key={index}
                      src={image}
                      alt={`Thumbnail ${index + 1}`}
                      className="thumbnail-image"
                      onClick={() => handleImageClick(image)}
                    />
                  ))}
                </div>
              </div>
              <div className="product-info-adicional">
                <h1 className="product-title-adicional"><strong>{product.nombre_producto}</strong></h1>
                <div className="product-sku-id">
                  <p className="product-adicional-info">Id producto: {product.id_producto}</p>
                  <p className="product-adicional-info"> {getCategoryName(product.id_categoria)}</p>
                </div>
                <div className="product-pricing">
                  <p className="product-adicional-info">Nombre de la sucursal: {sucursal.nombre_sucursal}</p>
                  <p className="product-adicional-info">Precio del proveedor: ${parseFloat(product.precio_proveedor).toLocaleString()}</p>
                  <p className="product-adicional-info">Precio sugerido: ${parseFloat(product.precio_sugerido).toLocaleString()}</p>
                  <p className="product-adicional-info">Stock: {product.cantidad_disponible}</p>
                </div>
                <div className="product-actions">
                  <button style={{background: "#723ef8"}} onClick={() => handleButtonClick(product.precio_sugerido, product.cantidad_disponible, product.nombre_producto, product.id_producto)}>
                    Ver
                  </button>
                </div>
              </div>
            </div>          
          </div>
          <div className="product-card-adicional">
            <div className="product-card-header">
              <div className="product-image-section">
                <img
                  src={selectedImage}
                  alt={product.nombre_producto}
                  className="product-image"
                />
                <div className="product-thumbnails">
                  {images.map((image, index) => (
                    <img
                      key={index}
                      src={image}
                      alt={`Thumbnail ${index + 1}`}
                      className="thumbnail-image"
                      onClick={() => handleImageClick(image)}
                    />
                  ))}
                </div>
              </div>
              <div className="product-info-adicional">
                <h1 className="product-title-adicional"><strong>{product.nombre_producto}</strong></h1>
                <div className="product-sku-id">
                  <p className="product-adicional-info">Id producto: {product.id_producto}</p>
                  <p className="product-adicional-info"> {getCategoryName(product.id_categoria)}</p>
                </div>
                <div className="product-pricing">
                  <p className="product-adicional-info">Nombre de la sucursal: {sucursal.nombre_sucursal}</p>
                  <p className="product-adicional-info">Precio del proveedor: ${parseFloat(product.precio_proveedor).toLocaleString()}</p>
                  <p className="product-adicional-info">Precio sugerido: ${parseFloat(product.precio_sugerido).toLocaleString()}</p>
                  <p className="product-adicional-info">Stock: {product.cantidad_disponible}</p>
                </div>
                <div className="product-actions">
                  <button style={{background: "#723ef8"}} onClick={() => handleButtonClick(product.precio_sugerido, product.cantidad_disponible, product.nombre_producto, product.id_producto)}>
                    Ver
                  </button>
                </div>
              </div>
            </div>          
          </div>
          <div className="product-card-adicional">
            <div className="product-card-header">
              <div className="product-image-section">
                <img
                  src={selectedImage}
                  alt={product.nombre_producto}
                  className="product-image"
                />
                <div className="product-thumbnails">
                  {images.map((image, index) => (
                    <img
                      key={index}
                      src={image}
                      alt={`Thumbnail ${index + 1}`}
                      className="thumbnail-image"
                      onClick={() => handleImageClick(image)}
                    />
                  ))}
                </div>
              </div>
              <div className="product-info-adicional">
                <h1 className="product-title-adicional"><strong>{product.nombre_producto}</strong></h1>
                <div className="product-sku-id">
                  <p className="product-adicional-info">Id producto: {product.id_producto}</p>
                  <p className="product-adicional-info"> {getCategoryName(product.id_categoria)}</p>
                </div>
                <div className="product-pricing">
                  <p className="product-adicional-info">Nombre de la sucursal: {sucursal.nombre_sucursal}</p>
                  <p className="product-adicional-info">Precio del proveedor: ${parseFloat(product.precio_proveedor).toLocaleString()}</p>
                  <p className="product-adicional-info">Precio sugerido: ${parseFloat(product.precio_sugerido).toLocaleString()}</p>
                  <p className="product-adicional-info">Stock: {product.cantidad_disponible}</p>
                </div>
                <div className="product-actions">
                  <button style={{background: "#723ef8"}} onClick={() => handleButtonClick(product.precio_sugerido, product.cantidad_disponible, product.nombre_producto, product.id_producto)}>
                    Ver
                  </button>
                </div>
              </div>
            </div>          
          </div>
        </div>*/}
      </div>
    </>
  );
};

export default ProductCard;
