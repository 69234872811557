import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import NavbarMex from '../newInterface/components/navbarMex';
import SendClientPage from '../dropShipingInterface/SendClientPage/SendClientShop';
import AgregarProductoShopify from './shopifyInventario/agregarProductoShopify';
import { Table, Button, Modal } from 'antd';
import 'antd/dist/antd.css'; // Importa los estilos de antd
import { useNavigate } from 'react-router-dom';
import './Shopify.css';

const ShopSubscription = () => {
  const [mostrarEnvios, setMostrarEnvios] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(null);
  const [ordersData, setOrdersData] = useState([]);
  const [formData, setFormData] = useState({
    apiKey: '',
    password: '',
    sharedSecret: '',
    apiVersion: '',
    shopName: '',
    codigo_sucursal: '',
    userId: ''
  });

  const navigate = useNavigate();

  // Función para verificar si la sucursal del usuario está suscrita
  const verificarSucursalUsuario = async () => {
    const userId = localStorage.getItem('usuario_id');
    if (!userId) {
      console.error('No se encontró el ID de usuario en localStorage');
      return;
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      userId: userId,
    }));

    try {
      const response = await axios.get(`https://integration.99envios.app/api/integration/shopify/v1/verificar-sucursal/${userId}`);
      const data = response.data;

      if (data.message === 'El suscriptor está activo.') {
        setIsSubscribed(true);
        setFormData((prevFormData) => ({
          ...prevFormData,
          sucursalId: data.codigo_sucursal
        }));
        localStorage.setItem('codigoSucursal', data.codigo_sucursal);
      } else {
        setIsSubscribed(false);
        setFormData((prevFormData) => ({
          ...prevFormData,
          codigo_sucursal: data.codigo_sucursal,
        }));
        Swal.fire({
          icon: 'warning',
          title: 'Sucursal no suscrita',
          text: 'Complete el formulario para suscribir la sucursal.',
        });
      }
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  };

  // Función para obtener los datos de las órdenes de Shopify
  const fetchOrdersData = async () => {
    try {
      const codigoSucursal = localStorage.getItem('codigoSucursal') || formData.codigo_sucursal;
      const response = await axios.get(`https://integration.99envios.app/api/ordersShopify/${codigoSucursal}`);
      // Ordenar las órdenes por ID de forma descendente antes de establecerlas
      const sortedOrders = response.data.sort((a, b) => b.id - a.id);
      setOrdersData(sortedOrders);
    } catch (error) {
      console.error('Error al obtener los datos de la API:', error);
    }
  };


  useEffect(() => {
    verificarSucursalUsuario();
  }, []);

  useEffect(() => {
    if (isSubscribed) {

      fetchOrdersData();
    }
  }, [isSubscribed]);

  // Función para manejar el envío del formulario de suscripción
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const codigoSucursal = localStorage.getItem('codigoSucursal') || formData.codigo_sucursal;
      await axios.post(`https://integration.99envios.app/api/integration/shopify/v1/integration/${codigoSucursal}`, formData);

      await Swal.fire({
        icon: 'success',
        title: 'Suscripción exitosa',
        text: 'La sucursal ha sido suscrita correctamente.',
      });

      // Recargar la página después de mostrar el mensaje de éxito
      window.location.reload();

    } catch (error) {
      console.error('Error al enviar los datos:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Hubo un error al registrar la suscripción.',
      });
    }
  };

  // Función para generar la guía
  const handleGenerateGuide = (order) => {
    setSelectedOrder({
      ...order,
      total_weight: order.total_weight ?? 0, // Asigna 0 si total_weight es undefined
    });
    setMostrarEnvios(true);
  };

  // Definición de las columnas para la tabla de antd

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) =>  a.id- b.id , 
      defaultSortOrder: 'descend', 
    },
    {
      title: 'Order ID',
      dataIndex: 'order_id',
      key: 'order_id',
    },
    {
      title: 'Número de Orden',
      dataIndex: 'order_number',
      key: 'order_number',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Producto',
      dataIndex: 'product_title',
      key: 'product_title',
    },
    {
      title: 'Precio Unitario',
      dataIndex: 'product_price',
      key: 'product_price',
      render: (price) => `$${parseFloat(price).toLocaleString()}`,
    },
    {
      title: 'Cantidad',
      dataIndex: 'product_quantity',
      key: 'product_quantity',
    },
    {
      title: 'Subtotal',
      dataIndex: 'subtotal_price',
      key: 'subtotal_price',
      render: (subtotal) => `$${parseFloat(subtotal).toLocaleString()}`,
    },
    {
      title: 'Total',
      dataIndex: 'total_price',
      key: 'total_price',
      render: (total) => `$${parseFloat(total).toLocaleString()}`,
    },
    {
      title: 'Estado Financiero',
      dataIndex: 'financial_status',
      key: 'financial_status',
    },
    {
      title: 'Estado de Envío',
      dataIndex: 'fulfillment_status',
      key: 'fulfillment_status',
    },
    {
      title: 'Fecha de Creación',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text) => new Date(text).toLocaleString(),
    },
    {
      title: 'Acciones',
      key: 'actions',
      fixed: 'right',
      render: (_, record) => (
        <Button type="primary" onClick={() => handleGenerateGuide(record)}>
          Generar Guía
        </Button>
      ),
    }
  ];


  if (isSubscribed) {
    return (
      <>
        <Modal
          open={mostrarEnvios}
          title="Generar Guía de Envío"
          onCancel={() => setMostrarEnvios(false)}
          footer={null}
          width={1000}
        >
          {selectedOrder && (
            <SendClientPage selectedOrder={selectedOrder} />
          )}
        </Modal>

        <NavbarMex title="Órdenes de Shopify" />
        <div className="orders-container centered-container">
          {/* Botones superiores */}
          <div className="articulos-container centered-container">
            <AgregarProductoShopify />
          </div>

          {/* Título y tabla con scroll y botón fijo */}
          <h2>Lista de Órdenes</h2>
          <Table
            columns={columns}
            dataSource={ordersData}
            rowKey="id"
            scroll={{ x: 1500, y: 400 }} // Permite el scroll horizontal y vertical
            pagination={{ position: ["bottomCenter"] }}
            sticky
          />
        </div>
      </>
    );
  }

  return (
    <>
      <NavbarMex title="Integración a tienda" />


      <div className="shopify-container centered-container">
        <h2>Formulario de Integración a tienda</h2>
        <form onSubmit={handleSubmit} className="form-container">
          <div className="form-group">
            <label>Código Sucursal:</label>
            <input
              type="text"
              name="codigo_sucursal"
              value={formData.codigo_sucursal}
              onChange={(e) => setFormData({ ...formData, codigo_sucursal: e.target.value })}
              required
            />
          </div>

          <div className="form-group">
            <label>API Key:</label>
            <input
              type="text"
              name="apiKey"
              value={formData.apiKey}
              onChange={(e) => setFormData({ ...formData, apiKey: e.target.value })}
              required
            />
          </div>
          <div className="form-group">
            <label>Password:</label>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={(e) => setFormData({ ...formData, password: e.target.value })}
              required
            />
          </div>
          <div className="form-group">
            <label>Shared Secret:</label>
            <input
              type="text"
              name="sharedSecret"
              value={formData.sharedSecret}
              onChange={(e) => setFormData({ ...formData, sharedSecret: e.target.value })}
              required
            />
          </div>
          <div className="form-group">
            <label>API Version:</label>
            <input
              type="text"
              name="apiVersion"
              value={formData.apiVersion}
              onChange={(e) => setFormData({ ...formData, apiVersion: e.target.value })}
              required
            />
          </div>
          <div className="form-group">
            <label>Shop Name:</label>
            <input
              type="text"
              name="shopName"
              value={formData.shopName}
              onChange={(e) => setFormData({ ...formData, shopName: e.target.value })}
              required
            />
          </div>
          <div className="button-container">
            <button type="submit" className="submit-button">
              Registrar
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ShopSubscription;
