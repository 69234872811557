import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Input, Col, message } from 'antd';
import NavbarPicking from '../../components/navbarPicking/navbarPicking';
import './gestionBodegas.css';

const GestionBodegas = () => {
    const [bodegas, setBodegas] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedBodega, setSelectedBodega] = useState(null);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const fetchBodegas = async () => {
        try {
            setLoading(true);
            const userId = localStorage.getItem('usuario_id');
            const response = await fetch(`https://api.99envios.app/api/picking/bodega/listar-bodegas/${userId}`);
            
            if (!response.ok) {
                throw new Error('Error al cargar las bodegas');
            }
            
            const data = await response.json();
            setBodegas(data);
        } catch (error) {
            console.error('Error fetching bodegas:', error);
            message.error('No se pudieron cargar las bodegas');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchBodegas();
    }, []);

    const filteredBodegas = bodegas.filter((bodega) => 
        bodega.nombre?.toLowerCase().includes(searchText.toLowerCase()) ||
        bodega.ubicacion?.toLowerCase().includes(searchText.toLowerCase())
    );

    const handleSubmit = () => {
        form.validateFields()
            .then(values => {
                const updatedBodegas = bodegas.map(bodega => 
                    bodega.id === selectedBodega.id 
                        ? { ...bodega, ...values }
                        : bodega
                );
                setBodegas(updatedBodegas);
                setIsModalVisible(false);
                form.resetFields();
            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });
    };

    const columns = [
        { 
            title: 'Nombre', 
            dataIndex: 'nombre', 
            key: 'nombre' 
        },
        { 
            title: 'Ubicación', 
            dataIndex: 'ubicacion', 
            key: 'ubicacion' 
        },
        { 
            title: 'Inventario Disponible', 
            dataIndex: 'inventarioDisponible', 
            key: 'inventarioDisponible',
            render: (inventarioDisponible) => `${inventarioDisponible} unidades`
        },
        { 
            title: 'Inventario en Tránsito', 
            dataIndex: 'inventarioTransito', 
            key: 'inventarioTransito',
            render: (inventarioTransito) => `${inventarioTransito} unidades`
        },
        {
            title: 'Acciones',
            key: 'actions',
            render: (_, record) => (
                <Button 
                    type="link" 
                    onClick={() => {
                        setSelectedBodega(record);
                        form.setFieldsValue(record);
                        setIsModalVisible(true);
                    }}
                >
                    Gestionar
                </Button>
            ),
        },
    ];

    return (
        <>
            <NavbarPicking title="Gestión Bodegas" />
            <div className="GB-table-container" style={{ fontSize: "13px" }}>
                <Col>
                    <Input
                        placeholder="Buscar bodega"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        style={{ width: 200, marginBottom: 16 }}
                    />
                </Col>
                <Table 
                    dataSource={filteredBodegas} 
                    columns={columns} 
                    rowKey="id" 
                    pagination={{ pageSize: 5 }} 
                    scroll={{ x: '450' }}
                    loading={loading}
                />
            </div>
            <Modal
                title="Gestionar Bodega"
                visible={isModalVisible}
                onCancel={() => {
                    setIsModalVisible(false);
                    form.resetFields();
                }}
                footer={[
                    <Button 
                        style={{height: 44}} 
                        key="cancel" 
                        onClick={() => {
                            setIsModalVisible(false);
                            form.resetFields();
                        }}
                    >
                        Cancelar
                    </Button>,
                    <Button 
                        style={{height: 44}} 
                        key="submit" 
                        type="primary" 
                        onClick={handleSubmit}
                    >
                        Guardar
                    </Button>
                ]}
            >
                <Form form={form} layout="vertical">
                    <Form.Item 
                        label="Nombre"
                        name="nombre"
                        rules={[{ required: true, message: 'Por favor ingrese el nombre' }]}
                        style={{ marginBottom: '15px' }}
                    >
                        <Input style={{ width: '270px' }} />
                    </Form.Item>

                    <Form.Item 
                        label="Ubicación"
                        name="ubicacion"
                        rules={[{ required: true, message: 'Por favor ingrese la ubicación' }]}
                        style={{ marginBottom: '15px' }}
                    >
                        <Input style={{ width: '270px' }} />
                    </Form.Item>

                    <Form.Item 
                        label="Inventario Disponible"
                        name="inventarioDisponible"
                        rules={[
                            { required: true, message: 'Por favor ingrese el inventario' },
                            { type: 'number', min: 0, message: 'El inventario debe ser mayor o igual a 0' }
                        ]}
                        style={{ marginBottom: '15px' }}
                    >
                        <Input 
                            type="number"
                            style={{ width: '270px' }}
                            min={0}
                        />
                    </Form.Item>
                    <Form.Item 
                        label="Inventario en Tránsito"
                        name="inventarioTransito"
                        rules={[
                            { required: true, message: 'Por favor ingrese el inventario en tránsito' },
                            { type: 'number', min: 0, message: 'El inventario debe ser mayor o igual a 0' }
                        ]}
                        style={{ marginBottom: '15px' }}
                    >
                        <Input 
                            type="number"
                            style={{ width: '270px' }}
                            min={0}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default GestionBodegas;