import React from 'react';
import { Table, Input, Button } from 'antd';
import NavbarSales from '../../components/navbar/navbarSales';
import 'antd/dist/antd.css';
import './DatosSucursales.css';
import { SearchOutlined, FileExcelOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';

const DatosSucursales = () => {

  const columns = [
    {
      title: 'Código Sucursal',
      dataIndex: 'codigoSucursal',
      key: 'codigoSucursal',
      sorter: (a, b) => a.codigoSucursal.localeCompare(b.codigoSucursal),
    },
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
    },
    {
      title: 'Teléfono',
      dataIndex: 'telefono',
      key: 'telefono',
    },
    {
      title: 'Ciudad',
      dataIndex: 'ciudad',
      key: 'ciudad',
    },
    {
      title: 'Usuario',
      dataIndex: 'usuario',
      key: 'usuario',
    },
    {
      title: 'Contraseña',
      dataIndex: 'contrasena',
      key: 'contrasena',
    },
    {
      title: 'Cantidad Envios',
      dataIndex: 'cantidadEnvios',
      key: 'cantidadEnvios',
      sorter: (a, b) => a.cantidadEnvios - b.cantidadEnvios,
    },
  ];

  const data = [
    {
      key: '1',
      codigoSucursal: '25093',
      nombre: 'Tienda Virtual L&D99',
      telefono: '3112879975',
      ciudad: 'BOGOTÁ',
      usuario: 'CLIENTE.ILOVE158',
      contrasena: 'Inter1123160298',
      cantidadEnvios: '',
    },
    {
      key: '2',
      codigoSucursal: '52557',
      nombre: 'Keisly_Store 99',
      telefono: '3024292361',
      ciudad: '',
      usuario: 'CLIENTE.ILOVE1226',
      contrasena: 'Inter37399245',
      cantidadEnvios: '',
    },
    {
      key: '3',
      codigoSucursal: '101297',
      nombre: 'Edgar Nuñez',
      telefono: '3045249859',
      ciudad: 'BOGOTÁ',
      usuario: 'CLIENTE.ILOVE2180',
      contrasena: 'Inter17959399',
      cantidadEnvios: 10,
    },
    {
      key: '4',
      codigoSucursal: '101546',
      nombre: 'EL CASTILLO',
      telefono: '3102171380',
      ciudad: 'BOGOTÁ',
      usuario: 'CLIENTE.ILOVE2235',
      contrasena: 'Inter91296921',
      cantidadEnvios: 1,
    },
    // Agrega más registros según sea necesario
  ];

  const handleExportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'DatosSucursales');
    XLSX.writeFile(workbook, 'DatosSucursales.xlsx');
  };

  return (
    <>
        <NavbarSales title="Datos Sucursales" />
    <div className="datos-sucursales-container">
      <div className="datos-sucursales-header">
        <h2>Tabla de datos de Sucursales a cargo</h2>
        <Button
          type="primary"
          icon={<FileExcelOutlined />}
          onClick={handleExportToExcel}
          className="datos-sucursales-export-btn"
        >
          Exportar a Excel
        </Button>
      </div>

      <div className="datos-sucursales-table">
        <Table
          columns={columns}
          dataSource={data}
          pagination={{ pageSize: 5 }}
          bordered
          title={() => 'Datos Sucursales'}
          rowKey="codigoSucursal"
        />
      </div>
    </div>
    </>
  );
};

export default DatosSucursales;
