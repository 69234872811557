import React, { useState } from 'react';
import { Button, message, Spin, Table, Modal, Radio, Select } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import NavbarDropshipping from '../navbarDropshipping/navbarDropshipping';
import { DownloadOutlined } from '@ant-design/icons'; // Importar el icono de descarga
import axios from 'axios';
import * as XLSX from 'xlsx';
import './masivoDrop.css'; // Asegúrate de tener este archivo si usas estilos personalizados

const { Option } = Select;

const MasivoDrop = () => {
  const [excelData, setExcelData] = useState([]);
  const [cotizaciones, setCotizaciones] = useState([]);
  const [orderResponse, setOrderResponse] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [globalTransportadora, setGlobalTransportadora] = useState(null); // Nueva variable para selección global
  const [useGlobalTransportadora, setUseGlobalTransportadora] = useState(false); // Alternar entre selección global o individual

  // Subida de archivo y conversión a JSON
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);

      setExcelData(jsonData);
    };

    reader.readAsArrayBuffer(file);
  };

  // Función para formatear las monedas
  const formatCurrency = (value, locale = 'es-CO', currency = 'COP') => {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(value);
  };

  // Manejo de cotización
  const handleCotizarClick = async () => {
    setLoading(true);
    try {
      if (excelData.length === 0) {
        message.error('Por favor, sube un archivo Excel.');
        return;
      }

      const cotizacionesList = [];

      for (const item of excelData) {
        const {
          'Nombre Destinatario': firstName,
          'Apellido Destinatario': lastName,
          'Teléfono Destinatario': phone,
          'Email Destinatario': email,
          'Dirección Destinatario': address,
          'Ciudad Destinatario': city,
          'Producto': product,
          'Cantidad': quantity,
          'Precio Venta': salePrice,
          'Peso Producto': pesoProducto,
        } = item;

        if (!firstName || !lastName || !city || !product || !quantity || !salePrice || !pesoProducto) {
          message.error(`Faltan datos en el Excel para el producto ${product}`);
          continue;
        }

        // Preparar datos para la API de cotización
        const cotizacionData = {
          destino: { codigo: String(city) }, // Asegurando que el código sea string
          origen: { codigo: "" }, // Código de ejemplo para la ciudad de origen
          IdTipoEntrega: 1,
          IdServicio: 1,
          peso: pesoProducto * quantity,
          largo: 1,
          ancho: 1,
          alto: 1,
          fecha: "28-06-2024",
          AplicaContrapago: true, // Siempre enviar como true
          valorDeclarado: salePrice * quantity,
        };

        try {
          const codigo_sucursal = localStorage.getItem('codigoSucursal');
          const response = await axios.post(
            `https://integration.99envios.app/api/sucursal/cotizar/${codigo_sucursal}`,
            cotizacionData
          );

          // Calcular precios totales para cada transportadora
          const transportadoras = ['interrapidisimo', 'tcc', 'servientrega', 'coordinadora'];
          const cotizacionTransportadoras = {};

          for (const transportadora of transportadoras) {
            const cotizacion = response.data[transportadora];
            if (cotizacion && cotizacion.exito) {
              const totalCotizacion = cotizacion.valor + cotizacion.valor_contrapago + (cotizacion.comision_interna || 0) + (cotizacion.sobreflete || 0);
              cotizacionTransportadoras[transportadora] = {
                total: totalCotizacion,
                ...cotizacion
              };
            }
          }

          cotizacionesList.push({
            itemData: item,
            cotizacion: cotizacionTransportadoras,
            selectedTransportadora: null,
          });

        } catch (error) {
          console.error(`Error al cotizar para el producto ${product}:`, error);
          cotizacionesList.push({
            itemData: item,
            cotizacion: null,
            error: 'Error al cotizar',
          });
        }
      }

      setCotizaciones(cotizacionesList);
      message.success('Cotizaciones generadas con éxito');
    } catch (error) {
      message.error('Error al generar cotizaciones');
    } finally {
      setLoading(false);
    }
  };

  const handleTransportadoraChange = (index, transportadora) => {
    if (useGlobalTransportadora) {
      return; // Evitar selección individual si hay una transportadora global
    }
    const updatedCotizaciones = [...cotizaciones];
    updatedCotizaciones[index].selectedTransportadora = transportadora;
    setCotizaciones(updatedCotizaciones);
  };

  // Manejo de confirmación de pedidos
  const handleConfirmarClick = async () => {
    setLoading(true);
    try {
      if (cotizaciones.length === 0) {
        message.error('Primero genera las cotizaciones.');
        return;
      }

      const ordenesList = [];

      for (const cotizacionItem of cotizaciones) {
        const { itemData, cotizacion } = cotizacionItem;

        // Priorizar la transportadora global si está seleccionada
        const selectedTransportadora = useGlobalTransportadora ? globalTransportadora : cotizacionItem.selectedTransportadora;

        if (!selectedTransportadora) {
          message.error(`No se ha seleccionado ninguna transportadora para el producto ${itemData.Producto}`);
          continue;
        }

        const cotizacionSeleccionada = cotizacion[selectedTransportadora];
        const totalGanar = (itemData['Precio Venta'] * itemData['Cantidad']) - cotizacionSeleccionada.total;

        if (totalGanar < 0) {
          ordenesList.push({
            itemData,
            orden: null,
            error: 'El monto a ganar es negativo. No se puede confirmar y enviar el pedido.',
          });
          continue;
        }

        const orderData = {
          IdProducto: itemData['ID Producto'],
          IdVariacion: itemData['ID Variación'] || '',
          Cantidad: String(itemData['Cantidad']), // Convertir cantidad a string
          AplicaContrapago: true, // Siempre enviar como true
          valorDeclarado: itemData['Precio Venta'],
          Destinatario: {
            nombre: itemData['Nombre Destinatario'],
            primerApellido: itemData['Apellido Destinatario'].split(' ')[0] || '',
            segundoApellido: itemData['Apellido Destinatario'].split(' ')[1] || '',
            telefono: itemData['Teléfono Destinatario'],
            direccion: itemData['Dirección Destinatario'],
            idLocalidad: String(itemData['Ciudad Destinatario']), // Asegurando que el código sea string
            correo: itemData['Email Destinatario'],
          },
          Observaciones: itemData['Notas Proveedor'],
          transportadora: {
            pais: 'colombia',
            nombre: selectedTransportadora, // Usar la transportadora seleccionada
          },
          origenCreacion: 1,
        };

        const id = localStorage.getItem('usuario_id');
        const token = localStorage.getItem('token');

        const config = {
          headers: {
            'Authorization': token ? `Bearer ${token}` : '',
            'Content-Type': 'application/json',
          },
        };

        try {
          const response = await axios.post(`https://99envios.app/api/orden_save/${id}`, orderData, config);

          if (response.status === 200) {
            ordenesList.push({
              itemData,
              orden: response.data,
              totalGanar,
              error: null,
            });
            message.success(`Pedido para ${itemData['Producto']} confirmado y enviado con éxito`);
          } else {
            throw new Error('Error al enviar el pedido');
          }
        } catch (error) {
          console.error(`Error al enviar el pedido para ${itemData['Producto']}:`, error);
          ordenesList.push({
            itemData,
            orden: null,
            error: 'Error al enviar el pedido',
          });
          message.error(`Error al enviar el pedido para ${itemData['Producto']}`);
        }
      }

      setOrderResponse(ordenesList);
      setIsModalVisible(true);
    } catch (error) {
      message.error('Error al enviar las órdenes');
    } finally {
      setLoading(false);
    }
  };

  const handleModalOk = () => {
    setIsModalVisible(false);
    setOrderResponse([]);
  };

  return (
    <>
      <NavbarDropshipping title="Dropshipping Masivo" />
      <Spin spinning={loading}>
        <div className="envios">
          <h1>Masivo Drop</h1>
          <input type="file" accept=".xlsx, .xls" onChange={handleFileUpload} />

          <Button
            type="primary"
            icon={<DownloadOutlined />}
            style={{ backgroundColor: '#107C41', borderColor: '#107C41', marginTop: '10px', maxWidth: '238px', marginLeft: '37%' }}
            href="/masivo_drop_ejemplo.xlsx"// Ruta del archivo a
            download
          >
            Descargar Plantilla Excel
          </Button>

          {/* Contenedor para alinear los botones */}
          <div className="button-container">
            <Button type="primary" onClick={handleConfirmarClick} disabled={loading || cotizaciones.length === 0}>
              Confirmar y Enviar Pedidos
            </Button>
            <Button type="primary" onClick={handleCotizarClick} disabled={loading || excelData.length === 0}>
              Cotizar Masivamente
            </Button>
          </div>

          <div style={{ marginTop: '20px', marginBottom: '20px', textAlign: 'center' }}>
            <Radio.Group value={useGlobalTransportadora} onChange={() => setUseGlobalTransportadora(!useGlobalTransportadora)}>
              <Radio value={true}>Usar una misma transportadora para todas las ordenes</Radio>
              <Radio value={false}>Seleccionar una transportadora por cada orden</Radio>
            </Radio.Group>
          </div>

          {useGlobalTransportadora && (
            <Select
              style={{ width: 450, marginBottom: '20px', marginLeft: '28%' }}
              placeholder="Seleccionar transportadora para todas las cotizaciones"
              onChange={(value) => setGlobalTransportadora(value)} // Asignar transportadora global
              value={globalTransportadora}
            >
              <Option value="interrapidisimo">Interrapidisimo</Option>
              <Option value="tcc">TCC</Option>
              <Option value="servientrega">Servientrega</Option>
              <Option value="coordinadora">Coordinadora</Option>
            </Select>
          )}

          {/* Mostrar cotizaciones */}
          {cotizaciones.length > 0 && (
            <div style={{ marginTop: '20px' }}>
              <h2>Cotizaciones</h2>
              {cotizaciones.map((cotizacionItem, index) => (
                <div key={index} style={{ marginBottom: '20px' }}>
                  <h3>Producto: {cotizacionItem.itemData['Producto']}</h3>
                  <Table
                    dataSource={Object.entries(cotizacionItem.cotizacion).map(([transportadora, data]) => ({
                      transportadora,
                      ...data,
                      total: data.total
                    }))}
                    columns={[
                      {
                        title: 'Transportadora',
                        dataIndex: 'transportadora',
                        key: 'transportadora',
                      },
                      {
                        title: 'Valor Cotización',
                        dataIndex: 'total',
                        key: 'total',
                        render: (text) => formatCurrency(text),
                      },
                      {
                        title: 'Mensaje',
                        dataIndex: 'mensaje',
                        key: 'mensaje',
                      },
                      {
                        title: 'Seleccionar',
                        key: 'select',
                        render: (_, record) => (
                          <Radio
                            checked={cotizacionItem.selectedTransportadora === record.transportadora}
                            onChange={() => handleTransportadoraChange(index, record.transportadora)}
                            disabled={useGlobalTransportadora} // Deshabilitar si se está usando una transportadora global
                          >
                            Seleccionar
                          </Radio>
                        ),
                      }

                    ]}
                    rowKey={(record) => record.transportadora}
                    pagination={false}
                  />
                </div>
              ))}
            </div>
          )}

          {/* Modal de confirmación de órdenes */}
          <Modal
            title={<span><CheckCircleOutlined style={{ color: 'green' }} /> Confirmación de Órdenes</span>}
            visible={isModalVisible}
            onOk={handleModalOk}
            onCancel={handleModalOk}
            footer={[
              <Button key="ok" type="primary" onClick={handleModalOk}>
                Confirmar
              </Button>,
            ]}
            centered
            className="modal-contentMD"
          >
            {orderResponse.map((item, index) => (
              <div key={index} style={{ marginBottom: '20px' }}>
                <h4>Producto: {item.itemData['Producto']}</h4>
                {item.orden ? (
                  <div>
                    <p><strong>Mensaje:</strong> {item.orden.mensaje}</p>
                    <p><strong>Número de Orden:</strong> {item.orden.numero_orden}</p>
                    <p><strong>Utilidad:</strong> {formatCurrency(item.totalGanar)}</p>
                  </div>
                ) : (
                  <p>Error: {item.error}</p>
                )}
              </div>
            ))}
          </Modal>
        </div>
      </Spin>
    </>
  );

};

export default MasivoDrop;
