import React, { useEffect, useState } from 'react';
import { Form, Select, Input, Button, Card } from 'antd';
import NavbarPicking from '../../components/navbarPicking/navbarPicking';
import './FormularioBodega.css'; // Estilos personalizados

const { Option } = Select;

const FormularioBodega = () => {
    const [form] = Form.useForm();
    const [codigoSucursal, setCodigoSucursal] = useState('');

    useEffect(() => {
        // Obtener el código de sucursal desde localStorage
        const codigo = localStorage.getItem('codigoSucursal');
        if (codigo) {
            setCodigoSucursal(codigo);
            form.setFieldsValue({
                codigoSucursal: codigo, // Establece el valor del formulario
            });
        }
    }, [form]);

    const onFinish = (values) => {
        console.log('Valores del formulario:', values);
    };

    return (
        <>
            <NavbarPicking title="Selección de Bodega" />
            <div className="formulario-bodega-container">
                <Card title="Selecciona una Bodega" className="formulario-bodega-card">
                    <Form
                        form={form}
                        name="bodegaForm"
                        layout="vertical"
                        onFinish={onFinish}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Selecciona Bodega:"
                            name="bodega"
                            rules={[{ required: true, message: 'Por favor selecciona una bodega' }]}
                        >
                            <Select placeholder="Selecciona una bodega">
                                <Option value="bodega1">Bodega 1</Option>
                                <Option value="bodega2">Bodega 2</Option>
                                <Option value="bodega3">Bodega 3</Option>
                            </Select>
                        </Form.Item>

                        {/* Campo Código de Sucursal no editable */}
                        <Form.Item
                            label="Código de Sucursal:"
                            name="codigoSucursal"
                            rules={[{ required: true, message: 'Por favor ingrese el código de sucursal' }]}
                        >
                            <Input value={codigoSucursal} disabled /> {/* Campo deshabilitado */}
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="formulario-bodega-submit-button">
                                Confirmar Selección
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </div>
        </>
    );
};

export default FormularioBodega;
