import React, { useState } from 'react';
import NavbarAdminMex from '../components/navbar';
import BotonWhatsapp from '../../newInterface/botonWhatsapp/botonWhatsapp';
import { Modal, message } from 'antd';
import './eliminarGuia.css';

const DesactivarGuia = () => {
  const [trackingNumber, setTrackingNumber] = useState('');
  const [carrier, setCarrier] = useState('interrapidisimo');

  const handleInputChange = (event) => {
    setTrackingNumber(event.target.value);
  };

  const handleCarrierChange = (event) => {
    setCarrier(event.target.value);
  };

  const handleDelete = async () => {
    const requestBody = {
      guia: trackingNumber,
      transportadora: {
        pais: 'colombia',
        nombre: carrier
      }
    };

    Modal.confirm({
        title: 'Confirmación',
        content: '¿Estás seguro de que deseas desactivar esta guía?',
        onOk: async () => {
          try {
            const usuario_id = localStorage.getItem('usuario_id');
            const token = localStorage.getItem('token');

            const response = await fetch(`https://99envios.app/api/online/desactivar_preenvio/${usuario_id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(requestBody)
            });

            if (!response.ok) {
                throw new Error('Error en la búsqueda de la guía');
            }

            const data = await response.json();
            if (data.message === "Preenvío desactivado correctamente") {
                message.success("Preenvío desactivado correctamente");
                setTrackingNumber(''); // Limpiar el campo después de éxito
            }
          } catch (error) {
              console.error('Error:', error);
              message.error('Error al desactivar el preenvío');
          }
        }
    });
  };

  return (
    <>
      <NavbarAdminMex title="Desactivar guía"/>
      <div className="eliminar-guia-tracking-container-container">
        <div className="eliminar-guia-tracking-container">
          <h2>Desactivar guía</h2>
          <p>Coloca el número de guía que deseas desactivar</p>
          <div className="eliminar-guia-search-bar">
            <input
              type="text"
              value={trackingNumber}
              onChange={handleInputChange}
              placeholder="Ingrese guía"
              className="eliminar-guia-search-input"
            />
            <select value={carrier} onChange={handleCarrierChange} className="eliminar-guia-carrier-select">
              <option value="interrapidisimo">Interrapidisimo</option>
              <option value="servientrega">Servientrega</option>
              <option value="coordinadora">Coordinadora</option>
              <option value="tcc">TCC</option>
            </select>
          </div>
          <div className="eliminar-guia-search-bar">
            <button onClick={handleDelete} className="eliminar-guia-search-button">Desactivar</button>
          </div>
        </div>
      </div>
      <BotonWhatsapp />
    </>
  );
};

export default DesactivarGuia;