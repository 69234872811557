import React, { useState, useEffect } from "react";
import NavbarAdminMex from '../components/navbar';
import { Card, Row, Col, message, Button } from 'antd';
import axios from 'axios';
import './modalTransportadoras.css';

const ModalTransportadoras = () => {
  const [estados, setEstados] = useState({
    interrapidisimo: 'Inactivo',
    servientrega: 'Inactivo',
    tcc: 'Inactivo',
    coordinadora: 'Inactivo',
  });
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);

  const transportadoras = [
    { nombre: 'Coordinadora', imagen: '/Images/coordi.png', ruta: '/rastreo_automatico_coordinadora' },
    { nombre: 'TCC', imagen: '/Images/tcc.svg', ruta: '/rastreo_automatico_tcc' },
    { nombre: 'Servientrega', imagen: '/Images/servi.png', ruta: '/rastreo_automatico_servientrega' },
    { nombre: 'Interrapidisimo', imagen: '/Images/inter.png', ruta: '/rastreo_automatico_interrapidisimo' },
  ];

  useEffect(() => {
    let timer;
    if (isButtonDisabled && timeLeft > 0) {
      timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
    } else if (timeLeft === 0) {
      setIsButtonDisabled(false);
    }
    return () => clearTimeout(timer);
  }, [isButtonDisabled, timeLeft]);

  const ejecutarValorEstado = async (transportadora = null) => {
    if (!transportadora) {
      setIsButtonDisabled(true);
      setTimeLeft(1800); // 30 minutes in seconds
    }

    console.log("Ejecutando valor de estado");
    const baseUrl = 'https://integration.99envios.app/api/sucursal';

    const transportadorasToProcess = transportadora ? [transportadora] : transportadoras;

    for (const trans of transportadorasToProcess) {
      try {
        const response = await axios.post(`${baseUrl}${trans.ruta}`, {});
        
        if (response.data && response.data.estado) {
          setEstados(prevEstados => ({
            ...prevEstados,
            [trans.nombre.toLowerCase()]: response.data.estado
          }));
        }
        
        console.log(`Respuesta para ${trans.nombre}:`, response.data);
        message.success(`Llamada a la API exitosa para ${trans.nombre}`);
      } catch (error) {
        console.error(`Error al llamar a la API para ${trans.nombre}:`, error);
        message.error(`Error en la llamada a la API para ${trans.nombre}`);
      }
    }

    if (!transportadora) {
      message.info("Estados actualizados para todas las transportadoras");
    }
  };

  return (
    <>
      <NavbarAdminMex title="Actualizar Estados" />
      <Card className="modal-transportadoras-main-card">
        <Row gutter={[16, 16]} className="modal-transportadoras-row">
          {transportadoras.map((transportadora) => (
            <Col xs={24} sm={12} md={8} lg={6} key={transportadora.nombre} className="modal-transportadoras-col">
              <Card
                title={transportadora.nombre}
                className="modal-transportadoras-transporter-card"
              >
                <img 
                  alt={transportadora.nombre} 
                  src={transportadora.imagen} 
                  className="modal-transportadoras-transporter-image"
                />
                <p className="modal-transportadoras-estado">
                  Estado: {estados[transportadora.nombre.toLowerCase()]}
                </p>
                {/* 
                <Button
                  onClick={() => ejecutarValorEstado(transportadora)}
                  className="modal-transportadoras-execute-button"
                >
                  Ejecutar valor de estado
                </Button>
                */}
              </Card>
            </Col>
          ))}
        </Row>
        <Row justify="center" className="modal-transportadoras-execute-all-row">
          <Col>
          <Button
            type="primary"
            onClick={() => ejecutarValorEstado()}
            disabled={isButtonDisabled}
            style={{ 
              backgroundColor: '#8c8cff', 
              color: 'white', 
              border: 'none', 
              padding: '8px 16px', 
              borderRadius: '4px', 
              cursor: 'pointer', 
              height: '44px', 
              display: 'block',
              margin: '0 auto' }}
          >
            {isButtonDisabled 
              ? `Espere ${Math.floor(timeLeft / 60)}:${(timeLeft % 60).toString().padStart(2, '0')}`
              : 'Ejecutar valor de estado para todas'}
          </Button>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default ModalTransportadoras;