import React, { useState, useEffect } from 'react';
import axios from 'axios';
import NavbarSales from '../../components/navbar/navbarSales';
import './dashboardSales.css';
import { Margin } from '@mui/icons-material';

const DashboardSales = () => {
  const [dashboardData, setDashboardData] = useState(null);

  const fetchData = async () => {
    try {
      const response = await axios.get('https://api.99envios.app/api/online/homeadmin');
      setDashboardData(response.data);
    } catch (error) {
      console.error('Error al obtener los datos de la API', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <NavbarSales title={"Vendedor"}/>
      <div className="dashboard-containerAD">
        {dashboardData ? (
          <div className="cards-containerAD">
            <div className="rowAD">
              <div className="cardAD">
                <div className="icon-circle-AD local-shipping-icon">
                  <span className="material-icons-sharp" style={{ marginBottom:"-1px" , fontSize:"29px"}}>  work_outline  </span>
                </div>
                <h3>Sucursales Activas</h3>
                <p>{dashboardData.cantidad_sucursales}</p>
              </div>
              <div className="cardAD">
                <div className="icon-circle-AD check-circle-icon">
                  <span className="material-icons-sharp" style={{ marginBottom:"-1px" , fontSize:"29px"}}>  sick  </span>
                </div>
                <h3>-10 Envios</h3>
                <p>{dashboardData.cantidad_paiments}</p>
              </div>
              <div className="cardAD">
                <div className="icon-circle-AD report-icon">
                  <span className="material-icons-sharp" style={{ marginBottom:"-1px" , fontSize:"29px"}}>  elevator  </span>
                </div>
                <h3>-20 Envios</h3>
                <p>{dashboardData.cantidad_preenvios_mes}</p>
              </div>
            </div>
            <div className="rowAD">
              <div className="cardAD">
                <div className="icon-circle-AD track-changes-icon">
                  <span className="material-icons-sharp" style={{ marginBottom:"-1px" , fontSize:"29px"}}>  transfer_within_a_station  </span>
                </div>
                <h3>-30 Envios </h3>
                {dashboardData.cantidad_estados_mes.map((estado, index) => (
                  <p key={index}>{estado.estado}: {estado.cantidad}</p>
                ))}
              </div>
              <div className="cardAD">
                <div className="icon-circle-AD public-icon">
                  <span className="material-icons-sharp" style={{ marginBottom:"-1px" , fontSize:"29px"}}>  get_app  </span>
                </div>
                <h3>Sin Movimiento</h3>
                {dashboardData.cantidad_devolucion_ciudad_mes.length > 0 ? (
                  dashboardData.cantidad_devolucion_ciudad_mes.map((devolucion, index) => (
                    <p key={index}>{devolucion.ciudad}: {devolucion.cantidad}</p>
                  ))
                ) : (
                    <p></p>
                )}
              </div>
            </div>
          </div>
        ) : (
          <p>Cargando datos...</p>
        )}
      </div>
    </>
  );
}

export default DashboardSales;
