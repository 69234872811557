import React from 'react';
import { Table, Button, Input } from 'antd';
import NavbarSales from '../components/navbar/navbarSales';
import { FileExcelOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';
import './SucursalesComisionadas.css';
import * as XLSX from 'xlsx';

const SucursalesComisionadas = () => {
  const columns = [
    {
      title: 'Código Sucursal',
      dataIndex: 'codigoSucursal',
      key: 'codigoSucursal',
      sorter: (a, b) => a.codigoSucursal.localeCompare(b.codigoSucursal),
    },
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
    },
    {
      title: 'Teléfono',
      dataIndex: 'telefono',
      key: 'telefono',
    },
    {
      title: 'Ciudad',
      dataIndex: 'ciudad',
      key: 'ciudad',
    },
  ];

  const data = [
    {
      key: '1',
      codigoSucursal: '101546',
      nombre: 'EL CASTILLO',
      telefono: '3102171380',
      ciudad: 'BOGOTÁ/CUNDINAMARCA',
    },
    {
      key: '2',
      codigoSucursal: '101557',
      nombre: 'MAMOR DISTRIBUCIONES',
      telefono: '3108584242',
      ciudad: 'IBAGUE/TOLIMA',
    },
    {
      key: '3',
      codigoSucursal: '101563',
      nombre: 'Lujos y accesorios SS',
      telefono: '3223653243',
      ciudad: 'SOACHA/CUNDINAMARCA',
    },
    {
      key: '4',
      codigoSucursal: '102115',
      nombre: 'PURA_PASION_FC',
      telefono: '3057510901',
      ciudad: 'CALI/VALLE DEL CAUCA',
    },
    {
      key: '5',
      codigoSucursal: '102116',
      nombre: 'SAMYS MODA',
      telefono: '3229523010',
      ciudad: 'CUCUTA/NORTE DE SANTANDER',
    },
    // Agrega más datos según sea necesario
  ];

  const handleExportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'SucursalesComisionadas');
    XLSX.writeFile(workbook, 'SucursalesComisionadas.xlsx');
  };

  return (
    <>
        <NavbarSales title="Sucursales Comisionadas" />
    <div className="sucursales-comisionadas-container">
      <div className="sucursales-comisionadas-header">
        <h2>Sucursales comisionadas</h2>
        <Button
          type="primary"
          icon={<FileExcelOutlined />}
          onClick={handleExportToExcel}
          className="sucursales-comisionadas-export-btn"
        >
          Exportar a Excel
        </Button>
      </div>

      <h3>Tabla comisiones</h3>
      
      <div className="sucursales-comisionadas-table">
        <Table
          columns={columns}
          dataSource={data}
          pagination={{ pageSize: 5 }}
          bordered
          title={() => 'Tabla comisiones'}
          rowKey="codigoSucursal"
        />
      </div>
    </div>
    </>
  );
};

export default SucursalesComisionadas;
