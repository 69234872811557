import React, { useState, useEffect } from 'react';
import { Table, Input, Select, Button, Space } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import NavbarPicking from '../components/navbarPicking/navbarPicking';
import './OrdenesyBodega.css';  // Estilos personalizados

const { Option } = Select;

const OrdenesyBodega = () => {
  const [data, setData] = useState([]); // Aquí se almacenan los datos de la tabla
  const [filteredData, setFilteredData] = useState([]); // Para almacenar los datos filtrados
  const [search, setSearch] = useState('');
  const [transportadoraFilter, setTransportadoraFilter] = useState('');
  const [estadoEnvioFilter, setEstadoEnvioFilter] = useState('');

  useEffect(() => {
    // Simulación de los datos que obtendríamos de una API
    const fetchData = () => {
      const data = [
        {
          key: '1',
          idProducto: '3',
          variacion: 'Sin talla / Sin color',
          nombreProducto: 'ABLANDADOR DE CARNE',
          fechaEstado: '2024-09-17 17:42:45',
          estadoEnvio: 'creado',
          transportadora: 'interrapidisimo',
          valorProveedor: '$12.000',
          cantidad: '1',
          valorTotal: '$12.000',
          utilidad: '$11.952',
          numeroGuia: '240016369063',
          guia: 'Descargar',
          destinatario: 'jesus jaramillo',
          telefono: '315',
          direccion: 'cra'
        },
        {
          key: '2',
          idProducto: '3',
          variacion: 'Sin talla / Sin color',
          nombreProducto: 'ABLANDADOR DE CARNE',
          fechaEstado: '2024-09-25 16:45:55',
          estadoEnvio: 'creado',
          transportadora: 'interrapidisimo',
          valorProveedor: '$12.000',
          cantidad: '1',
          valorTotal: '$12.000',
          utilidad: '$11.952',
          numeroGuia: '240016659561',
          guia: 'Descargar',
          destinatario: 'Jose marín',
          telefono: '304207676',
          direccion: 'puerto espejo, chilacoa torre 21 apto 404'
        },
        {
          key: '3',
          idProducto: '3',
          variacion: 'Sin talla / Sin color',
          nombreProducto: 'ABLANDADOR DE CARNE',
          fechaEstado: '2024-10-05 21:47:03',
          estadoEnvio: 'anulado',
          transportadora: 'tcc',
          valorProveedor: '$12.000',
          cantidad: '1',
          valorTotal: '$12.000',
          utilidad: '$11.952',
          numeroGuia: '610204038',
          guia: 'Descargar',
          destinatario: 'Juan Pérez',
          telefono: '301234567',
          direccion: 'Calle 123 #45-67'
        }
      ];
      setData(data);
      setFilteredData(data); // Inicialmente mostrar todos los datos
    };

    fetchData();
  }, []);

  // Función para manejar la búsqueda
  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearch(value);
    const filtered = data.filter(item => item.nombreProducto.toLowerCase().includes(value));
    setFilteredData(filtered);
  };

  // Función para manejar el filtro de transportadora
  const handleTransportadoraFilter = (value) => {
    setTransportadoraFilter(value);
    const filtered = data.filter(item => item.transportadora === value || !value);
    setFilteredData(filtered);
  };

  // Función para manejar el filtro de estado del envío
  const handleEstadoEnvioFilter = (value) => {
    setEstadoEnvioFilter(value);
    const filtered = data.filter(item => item.estadoEnvio === value || !value);
    setFilteredData(filtered);
  };

  // Columnas de la tabla
  const columns = [
    { title: '#', dataIndex: 'key', key: 'key' },
    { title: 'ID Producto', dataIndex: 'idProducto', key: 'idProducto' },
    { title: 'Variación', dataIndex: 'variacion', key: 'variacion' },
    { title: 'Nombre del producto', dataIndex: 'nombreProducto', key: 'nombreProducto' },
    { title: 'Fecha de estado', dataIndex: 'fechaEstado', key: 'fechaEstado' },
    { title: 'Estado del Envío', dataIndex: 'estadoEnvio', key: 'estadoEnvio' },
    { title: 'Transportadora', dataIndex: 'transportadora', key: 'transportadora' },
    { title: 'Valor de Proveedor', dataIndex: 'valorProveedor', key: 'valorProveedor' },
    { title: 'Cantidad', dataIndex: 'cantidad', key: 'cantidad' },
    { title: 'Valor Total', dataIndex: 'valorTotal', key: 'valorTotal' },
    { title: 'Utilidad', dataIndex: 'utilidad', key: 'utilidad' },
    { title: 'Número de Guía', dataIndex: 'numeroGuia', key: 'numeroGuia' },
    { title: 'Guía', dataIndex: 'guia', key: 'guia', render: () => <Button type="link" icon={<DownloadOutlined />} /> },
    { title: 'Destinatario', dataIndex: 'destinatario', key: 'destinatario' },
    { title: 'Teléfono', dataIndex: 'telefono', key: 'telefono' },
    { title: 'Dirección', dataIndex: 'direccion', key: 'direccion' }
  ];

  return (
    <>
    <NavbarPicking title="Órdenes y Bodega" />
    <div className="ordenes-y-bodega-container">
      <Space style={{ marginBottom: 16 }}>
        <Button>Acciones</Button>
        <Select
          placeholder="Filtrar por Transportadora"
          value={transportadoraFilter}
          onChange={handleTransportadoraFilter}
        >
          <Option value="">Todas</Option>
          <Option value="interrapidisimo">interrapidisimo</Option>
          <Option value="tcc">tcc</Option>
        </Select>

        <Select
          placeholder="Filtrar por Estado de Envío"
          value={estadoEnvioFilter}
          onChange={handleEstadoEnvioFilter}
        >
          <Option value="">Todos</Option>
          <Option value="creado">creado</Option>
          <Option value="anulado">anulado</Option>
        </Select>

        <Input.Search
          placeholder="Buscar"
          value={search}
          onChange={handleSearch}
          style={{ width: 200 }}
        />
      </Space>

      <Table columns={columns} dataSource={filteredData} pagination={{ pageSize: 5 }} />
    </div>
    </>
  );
};

export default OrdenesyBodega;
